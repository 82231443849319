import React, { useState, useEffect } from "react";
import { GetTopDebators } from "../Api/GetApi";
import "./Debators.scss";
import { defaultImage } from "../Utilities/Utils";

export const Debators = () => {
  const [debators, setDebators] = useState([]);

  useEffect(() => {
    GetTopDebators().then((response) => {
      setDebators(response.data);
    });
  }, []);

  return (
    <div className="Debators">
      <div className="section-card">
        <h2 className="title primary-color title-border">Top Debtors</h2>
        <div className="debators-area  scroll-bar">
          {debators?.slice(0, 3)?.map((item, index) => {
            return (
              <div className="debators-section" key={index}>
                <div className="debators-block">
                  <div className="debators-image">
                    <img
                      src={item.image}
                      alt="img"
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = defaultImage;
                      }}
                    />
                  </div>
                  <p className="content">{item.name}</p>
                </div>
                <p className="content debators-number">
                  {item.total_points === null ? "0" : item.total_points}
                </p>
              </div>
            );
          })}

          {/* <p className="small-content view">View All ({debators.length})</p> */}
        </div>
      </div>
    </div>
  );
};
