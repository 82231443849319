import ReactApexChart from "react-apexcharts";
import "./DonutChart.scss";
import React, { useState, useEffect } from "react";
import { GetPopularCategory } from "../Api/GetApi";

export const DonutChart = () => {
  const [seriesData, setSeriesData] = useState([]);
  const [labelsData, setLablesData] = useState([]);

  const options = {
    labels: labelsData,
    chart: {
      type: "donut",
      height: "1000px",
    },
    legend: {
      position: "right",
      horizontalAlign: "left",
      fontSize: "12px",
      fontFamily: "noto-sans, sans-serif ",
      floating: true,
      markers: {
        shape: "square",
        radius: 2,
      },
    },

    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        donut: {
          labels: {
            show: true,
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  useEffect(() => {
    GetPopularCategory().then((response) => {
      setSeriesData(response.data?.series);
      setLablesData(response.data?.labels);
    });
  }, []);

  return (
    <div className="donut-chart">
      <div className="section-card">
        <h2 className="title primary-color title-border">Popular Category</h2>
        <div className="donut-chart-section">
          <div>
            <ReactApexChart
              options={options}
              series={seriesData}
              type="donut"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
