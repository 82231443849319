import React, { useEffect, useState } from "react";
import { getStatement } from "../../Api/GetApi";
import { TabComponent } from "../../TabComponent/TabComponent";
import { ToasterProvider } from "../../Context/ToasterContext";

const StatementPage = () => {
  const title = [
    "Pending Statement",
    "Approved Statement",
    "Rejected Statement",
  ];
  const [statement, setStatement] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [updateStatement, setUpdateStatement] = useState(false);

  useEffect(() => {
    setcategoryList([]);
    getStatement().then((res) => {
      setStatement(res.data.statement_data);
      setcategoryList(res.data.category_data);
    });
  }, [updateStatement]);

  return (
    <div className="statement-page">
      <div className="block-card">
        <h1 className="large-title primary-color">Statement</h1>
        {categoryList.length > 0 && (
          <TabComponent
            title={title}
            data={statement}
            type="statement"
            categoryList={categoryList}
            updateStatement={updateStatement}
            setUpdateStatement={setUpdateStatement}
          />
        )}
      </div>
    </div>
  );
};

export default function StatementwithContext() {
  return (
    <ToasterProvider>
      <StatementPage />
    </ToasterProvider>
  );
}
