import React, { useState, useEffect } from "react";

import { useDropzone } from "react-dropzone";

function ImageDropzone(props) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [boxVisible, setboxVisible] = useState(false);

  useEffect(() => {
    props.onDrop(acceptedFiles);
  }, [acceptedFiles]);

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  const handleRemoveItem = (e) => {};

  const onShowBox = () => {
    setboxVisible(true);
  };
  const removeBox = () => {
    setboxVisible(false);
  };

  return (
    <div className="image-dropzone">
      <section onClick={onShowBox} className="">
        <div {...getRootProps({ className: "dropzone dropzone-field" })}>
          <input {...getInputProps()} onClick={handleRemoveItem} />

          <p>Browse to choose images</p>
        </div>
        {boxVisible ? (
          <aside>
            <ul>
              <p>{files}</p>
              <button onClick={() => removeBox()}>
                <i className="bx bxs-x-circle"></i>
              </button>
            </ul>
          </aside>
        ) : (
          ""
        )}
      </section>
    </div>
  );
}


export default ImageDropzone;
