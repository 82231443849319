import React, { useState, useEffect, useContext } from "react";
import { DeleteQueriesApi } from "../Api/DeleteApi";
import { GetQueries } from "../Api/GetApi";
import { ToasterContext, ToasterProvider } from "../Context/ToasterContext";
import { PopUp } from "../PopUp/PopUp";
import "./Queries.scss";

const Queries = () => {
  const [queriesData, SetQueriesData] = useState([]);
  const [showMore, setShowMore] = useState({});
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const [updateQueries, setUpdateQueries] = useState(false);
  const [visible, setVisible] = useState(false);
  const [queriesId, setQueriesId] = useState();

  useEffect(() => {
    GetQueries().then((res) => {
      SetQueriesData(res.data);
    });
  }, [updateQueries]);

  useEffect(() => {
    const reference = {};
    queriesData.forEach((item) => {
      reference[item.id] = false;
    });
    setShowMore(reference);
  }, [queriesData]);

  const toggleText = (id) => {
    console.log(id);
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [id]: !Boolean(prevShowMore[id]),
    }));
  };

  const deleteQueries = (id) => {
    DeleteQueriesApi(id).then((res) => {
      if (res.data.status === 200) {
        setUpdateQueries(!updateQueries);
        successMessage("Queries successfully deleted!");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <div className="queries-page">
      <div className="block-card  single-page-height">
        <div className="title-block">
          <h2 className="large-title primary-color">Queries</h2>
        </div>
        <div className="row">
          {queriesData?.map((item, index) => {
            return (
              <div className="col-lg-4 col-md-4 col-sm-6 child" key={index}>
                <div className="card-section">
                  <div className="justify">
                    <div>
                      <h4 className="title">{item.name}</h4>
                      <p className="email">{item.email}</p>
                    </div>
                    <div className="close-icon">
                      <i
                        class="bx bx-x"
                        onClick={() => {
                          openModal();
                          setQueriesId(item.id);
                          // deleteQueries(item.id);
                        }}
                      ></i>
                    </div>
                  </div>
                  <p className="description-block ">
                    <span
                      className={`small-content description ${
                        showMore[item.id] ? "show" : "hide"
                      }`}
                    >
                      {item.message}
                    </span>
                    {item.message.length > 300 && (
                      <span
                        className="small-content read-more"
                        onClick={() => {
                          toggleText(item.id);
                        }}
                      >
                        {showMore[item.id] ? " show less" : "read more"}
                      </span>
                    )}
                  </p>
                  <div>
                    <a
                      href={`mailto:${item.email}`}
                      type="submit"
                      className="main-btn"
                    >
                      Reply
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {visible && (
          <PopUp
            visible={visible}
            type="confirmation"
            closeModal={() => closeModal()}
            action={deleteQueries}
            id={queriesId}
          />
        )}
      </div>
    </div>
  );
};

export default function QueryWithCOntext() {
  return (
    <ToasterProvider>
      <Queries />
    </ToasterProvider>
  );
}
