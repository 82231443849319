import React from "react";
import "./PopUp.scss";

export const ConfirmationPopUp = (props) => {
  return (
    <div className="confirmtion-popup popup-padding">
      <h2 className="title">Are you sure you want to do that?</h2>
      <div className="btn-block">
        <button
          className="secondary-btn"
          onClick={() => {
            props.closeModal();
          }}
        >
          Cancel
        </button>
        <button
          className="main-btn"
          onClick={() => {
            props.action(props.id);
            props.closeModal();
          }}
        >
          OK
        </button>
      </div>
    </div>
  );
};
