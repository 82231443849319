import React from "react";

const Contributors = (props) => {
  return (
    <div className="cotributors">
      <div className="section-card">
        <h2 className="title primary-color title-border">Contributors</h2>
        <div className="cotributors-section">
          <div className="cotributors-block"></div>
        </div>
      </div>
    </div>
  );
};

export default Contributors;
