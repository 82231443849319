import React, { createContext, useState } from "react";
import Modal from "react-awesome-modal";

const PopUpContext = createContext({
  callApi: false,
  setCallApi: (bool) => {},
  visible: false,
  setVisible: (bool) => {},
  openModal: (bool) => {},
  closeModal: (bool) => {},
});

const PopUpProvider = ({ children }) => {
  const [callApi, setCallApi] = useState(false);
  const [visible, setVisible] = useState(false);

  const openModal = (bool) => {
    setVisible(true);
  };

  const closeModal = (bool) => {
    setVisible(false);
  };
  return (
    <PopUpContext.Provider
      value={{
        callApi,
        setCallApi,
        visible,
        setVisible,
        openModal,
        closeModal,
      }}
    >
      <Modal
        visible={visible}
        width="400"
        height="300"
        effect="fadeInUp"
        onClickAway={() => closeModal()}
      >
        <div>
          <h1>Title</h1>
          <p>Some Contents</p>
          <a href="javascript:void(0);" onClick={() => closeModal()}>
            Close
          </a>
        </div>
      </Modal>
      {children}
    </PopUpContext.Provider>
  );
};

const PopUpConsumer = PopUpContext.Consumer;
export { PopUpProvider, PopUpContext, PopUpConsumer };
