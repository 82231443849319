import React from "react";
import "./Stance.scss";

const Stance = () => {
  return (
    <section className="stance">
      <div className="stance-cover">
        <div className="stance-cover-image">
          <img src={require("../../../assets/images/m1.jpg")} />
        </div>
        <div className="stance-cover-block">
          <h2 className="title">All Drugs should be legalised.</h2>
          <div className="btn-block">
            <button className="transparent-btn">
              <i class="bx bx-like"></i>
              For
              <span>0</span>
            </button>{" "}
            <button className="transparent-btn">
              <i class="bx bx-like"></i>
              Against
              <span>0</span>
            </button>{" "}
            <button className="transparent-btn">
              <i class="bx bx-like"></i>
              Undecided
              <span>0</span>
            </button>
          </div>
        </div>
      </div>
      <div className="stance-section">
        <div className="stance-description">
          <div className="block-card">
            <div className="profile-block">
              <div className="profile-image">
                <img src={require("../../../assets/images/m1.jpg")} />
              </div>
              <div className="profile-info">
                <h4 className="block-title">Michael Wazowski</h4>
                <p className="small-content">21 June, 2021</p>
              </div>
            </div>
            <div className="stance-description-block">
              <h2 className="block-title">All Drugs should be legalised.</h2>
              <p className="content">
                Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                amet sint. Velit officia consequat duis enim velit mollit. Amet
                minim mollit non deserunt ullamco est sit aliqua dolor do amet
                sint. Velit officia consequat duis enim velit mollit. Amet minim
                mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                Velit officia consequat duis enim velit mollit. Amet minim
                mollit non deserunt ullamco est sit aliqua dolor do amet sint.
                Velit officia consequat duis enim velit mollit.
                <ul>
                  <li>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit.
                  </li>
                  <li>
                    Amet minim mollit non deserunt ullamco est sit aliqua dolor
                    do amet sint. Velit officia consequat duis enim velit
                    mollit.
                  </li>
                </ul>
              </p>
              <div className="btn-block">
                <div className="btn-content">
                  <button className="icon-btn like">
                    <i class="bx bxs-like"></i>
                  </button>
                  <span className="small-content">12</span>
                </div>
                <div className="btn-content">
                  <button className="icon-btn dislike">
                    <i class="bx bxs-dislike"></i>
                  </button>
                  <span className="small-content">8</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="stance-info section-gap">
          <div className="stance-info-section">
            <div className="stance-info-shape">
              <div className="circle-1"></div>
              <div className="circle-2"></div>
            </div>
            <div className="stance-info-status for">For</div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="stance-info-section">
            <div className="stance-info-status against">Against</div>

            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="stance-info-block">
              <div className="block-card">
                <div className="profile-block">
                  <div className="profile-image">
                    <img src={require("../../../assets/images/m1.jpg")} />
                  </div>
                  <div className="profile-info">
                    <h4 className="block-title">Michael Wazowski</h4>
                    <p className="small-content">21 June, 2021</p>
                  </div>
                </div>
                <p className="content">
                  Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                  amet sint. Velit officia consequat duis enim velit mollit.{" "}
                  <span className="small-content more">Read more....</span>
                </p>
                <div className="btn-block">
                  <div className="btn-content">
                    <button className="icon-btn like">
                      <i class="bx bxs-like"></i>
                    </button>
                    <span className="small-content">12</span>
                  </div>
                  <div className="btn-content">
                    <button className="icon-btn dislike">
                      <i class="bx bxs-dislike"></i>
                    </button>
                    <span className="small-content">8</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Stance;
