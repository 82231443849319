import axios from "axios";

// const baseURL = "https://admin.massdebators.axiossoftwork.com/";

const instance = axios.create({
  baseURL: window.location.origin,
  baseURL,
  headers: {
    "content-type": "application/json",
  },
  withCredentials: true,
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);
export default instance;
