import { ErrorMessage, Field, useFormikContext } from "formik";
import React from "react";
import ImageDropzone from "./ImageDropzone";

/**@type {React.FC<{index:number;name:string}>} */
const SubCategory = ({ index, name }) => {
  const formik = useFormikContext();
  return (
    <div className="sub-category">
      <div className="row">
        <div className="col-md-6">
          <p className="sub-content">Sub-category Name</p>
          <Field
            className="input-field ht-45"
            name={`${name}.${index}.title`}
            type="text"
            placeholder="Enter sub-category name"
          />
          <ErrorMessage
            name={`${name}.${index}.title`}
            component="div"
            className="error"
          />
        </div>
        <div className="col-md-6">
          <p className="sub-content">Choose Sub-category Image</p>

          <ImageDropzone
            files={formik.values[`${name}`][index]["image"]}
            setFiles={(files) => {
              formik.setFieldValue(`${name}.${index}.image`, files);
            }}
          />
          {/* {formik.errors[name]?.[index]?.["image"] && (
          <div className="error">{formik.errors[name]?.[index]?.["image"]}</div>
        )} */}
          <ErrorMessage
            name={`${name}.${index}.image`}
            component="div"
            className="error"
          />
        </div>
      </div>
    </div>
  );
};

export default SubCategory;
