import React, { useState, useEffect } from "react";
import "./ModeratorsPerformance.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import { GetModeartorPerformance } from "../Api/GetApi";
import { defaultImage } from "../Utilities/Utils";

export const ModeratorsPerformance = () => {
  const [performance, setPerformance] = useState([]);

  useEffect(() => {
    GetModeartorPerformance().then((res) => {
      setPerformance(res.data);
    });
  }, []);

  return (
    <div className="moderators-perfromance">
      <div className="section-gap-top">
        <div className="section-card">
          <h2 className="title primary-color title-border">
            Moderatos Performance
          </h2>
          <div className="moderators-performance-table">
            <table class="table table-borderless">
              <thead>
                <tr>
                  <th scope="col" className="small-content">
                    Sno.
                  </th>
                  <th scope="col" className="small-content">
                    Moderator
                  </th>
                  <th scope="col" className="small-content">
                    Preformance Rate
                  </th>
                </tr>
              </thead>
              <tbody>
                {performance?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="moderator-data">
                          <div className="moderator-data-image">
                            {item.user_type == "admin" ? (
                              <i class="bx bx-user admin-img"></i>
                            ) : (
                              <img
                                src={"uploads/" + item?.image}
                                alt="img"
                                className="moderator-img"
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src = defaultImage;
                                }}
                              />
                            )}
                          </div>
                          <p className="content">{item?.name}</p>
                        </div>
                      </td>
                      <td>
                        <div className="moderator-progressbar green">
                          <div className="progressbar-block">
                            <ProgressBar now={item?.performance} />
                            <p className="progressbar-number content">
                              {item.performance?.slice(0, 5)}%
                            </p>
                          </div>
                          <div className="progressbar-range">
                            <p className="range-number content">5.2%</p>
                            <i class="bx bxs-up-arrow-alt"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
