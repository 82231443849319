import React, { useState, useEffect, useContext } from "react";
import { postContributorUpdate } from "../Api/PostApi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./PopUp.scss";
import * as Yup from "yup";
import { ToasterContext } from "../Context/ToasterContext";

export const EditContributorPopUp = (props) => {
  /**@type {"createModerator" | "editmoderator"} */
  const [buttonDisable, setButtonDisabale] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  const type = props.type;
  const [image, setImage] = useState("http://i.pravatar.cc/500?img=7");

  useEffect(() => {
    const initialImage = props.itemObj?.image
      ? `${props.itemObj?.image}`
      : "http://i.pravatar.cc/500?img=7";
    setImage(initialImage);
  }, [props.itemObj]);

  return (
    <div className="edit-contributor-popup popup-padding">
      <Formik
        enableReinitialize
        initialValues={{
          name: props.itemObj?.name,
          user_name:
            props.itemObj?.user_name === "undefined"
              ? ""
              : props.itemObj?.user_name,
          email: props.itemObj?.email,
          image: props.itemObj?.image,
          dob: props.itemObj?.dob,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, "Must be 2 characters or more")
            .required("Required"),
          user_name: Yup.string()
            .min(2, "Must be 2 characters or more")
            .required("Required"),

          email: Yup.string()
            .email("Invalid email addresss`")
            .required("Required"),

          image: Yup.string().required("Required"),
          dob: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          var formData = new FormData();
          formData.append("name", values.name);
          formData.append("user_name", values.user_name);
          formData.append("email", values.email);
          formData.append("image", values.image);
          formData.append("dob", values.dob);
          postContributorUpdate(formData, props.itemObj.id).then((res) => {
            if (res.data.status === 200) {
              {
                successMessage("Contributor successfully Updated");
              }
              setButtonDisabale(true);
              setTimeout(() => {
                setButtonDisabale(false);
              }, 1000);
              props.closeModal();
              // alert("success");
              resetForm({
                values: {
                  name: "",
                  password: "",
                  email: "",
                  dob: "",
                  image: "",
                },
              });
              props.updateDOM();
            } else {
              errorMessage(res.data.message);
            }
          });
          setSubmitting(false);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="input-content-block">
                <label className="content">Image</label>

                <div className="avatar-upload">
                  <div className="avatar-edit">
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      onChange={(e) => {
                        const image = e.target.files[0];
                        const url = URL.createObjectURL(image);
                        setImage(url);
                        console.log({ url });
                        formik.setFieldValue("image", image);
                      }}
                    />
                    <label htmlFor="imageUpload">
                      <i className="bx bxs-pencil"></i>
                    </label>
                  </div>
                  <div className="avatar-preview">
                    <div
                      id="imagePreview"
                      style={{
                        backgroundImage: `url(${image})`,
                      }}
                    ></div>
                  </div>
                </div>

                <span className="error">
                  <ErrorMessage name="image" />
                </span>
              </div>
              <div className="input-content-block">
                <label className="content">Name</label>
                <Field
                  name="name"
                  type="text"
                  placeholder="Enter name"
                  className="input-field"
                />
                <span className="error">
                  <ErrorMessage name="name" />
                </span>
              </div>
              <div className="input-content-block">
                <label className="content">User Name</label>
                <Field
                  name="user_name"
                  type="text"
                  placeholder="Enter user name"
                  className="input-field"
                />
                <span className="error">
                  <ErrorMessage name="user_name" />
                </span>
              </div>

              <div className="input-content-block">
                <label className="content">Email</label>
                <Field
                  name="email"
                  type="email"
                  placeholder="Enter email address"
                  className="input-field"
                />
                <span className="error">
                  <ErrorMessage name="email" />
                </span>
              </div>
              <div className="input-content-block">
                <label className="content">DOB</label>
                <Field
                  name="dob"
                  type="date"
                  placeholder="Enter DOB"
                  className="input-field"
                />
                <span className="error">
                  <ErrorMessage name="dob" />
                </span>
              </div>

              <button
                type="submit"
                className="main-btn"
                disabled={buttonDisable}
              >
                Submit
              </button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
