import React, { useState, useEffect, useContext } from "react";
import "./NotificationView.scss";
import TimeAgo from "timeago-react";

import {
  GetAdminNotifications,
  GetModeratorNotifications,
} from "../Api/GetApi";
import {
  acceptDebates,
  postDebateReject,
  acceptModerator,
  rejectModerator,
} from "../Api/PostApi";
import { ToasterContext } from "../Context/ToasterContext";
import { defaultImage } from "../Utilities/Utils";

const NotificationView = (props) => {
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const [notificationData, setNotificationData] = useState();
  const [reload, setReload] = useState(false);
  const[acceptLoad,setAcceptLoad]=useState(false)
  const[rejectLoad,setRejectLoad]=useState(false)

  useEffect(() => {
    if (props.user_type === "admin") {
      GetAdminNotifications().then((response) => {
        setNotificationData(response.data.notification);
      });
    } else {
      GetModeratorNotifications().then((response) => {
        setNotificationData(response.data.notification);
      });
    }
  }, [reload]);

  const debateaccept = (id) => {
    setDAcceptLoad(true)
    var postData = {
      debate_id: id,
    };

    acceptDebates(postData).then((res) => {
      if (res.data.status === 200) {
        setReload(!reload);
        successMessage(res?.data?.message);
      } else {
        errorMessage(res?.data?.message);
      }
    }).finally(()=>{setAcceptLoad(false)})
  };

  const moderatorAccept = (id) => {
    setAcceptLoad(true)
    var postData = {
      id: id,
    };
    acceptModerator(postData).then((res) => {
      if (res.data.status === 200) {
        setReload(!reload);
        successMessage(res?.data?.message);
      } else {
        errorMessage(res?.data?.message);
      }
    }).finally(()=>{setAcceptLoad(false)})
  };

  const moderatorReject = (id) => {
    setRejectLoad(true)
    var postData = {
      id: id,
    };
    rejectModerator(postData).then((res) => {
      if (res.data.status === 200) {
        setReload(!reload);
        successMessage(res?.data?.message);
      } else {
        errorMessage(res?.data?.message);
      }
    }).finally(()=>setRejectLoad(false))
  };

  const debatereject = (id) => {
    setRejectLoad(true)
    var postData = {
      debate_id: id,
    };
    postDebateReject(postData).then((res) => {
      if (res.data.status === 200) {
        setReload(!reload);
        successMessage(res?.data?.message);
      } else {
        errorMessage(res?.data?.message);
      }
    }).finally(()=>setRejectLoad(false))
  };

  // const checkNotificationType = (item) => {
  //   var debateType = false;
  //   if (item.description.includes("debate")) {
  //     debateType = item.status === null;
  //   } else {
  //     debateType = item.moderator_status === null;
  //   }
  //   return debateType;
  // };
  return (
    <div className="notification-view">
      <div className="block-card">
        <div className="title-block">
          <h2 className="large-title primary-color">Notification</h2>
          {/* <p className="content">Mark all as read</p> */}
        </div>

        {notificationData?.slice(0, 10)?.map((item, index) => {
          return (
            <div className="notification-view-section" key={index}>
              <div className="notification-view-block">
                <div className="notification-view-image">
                  <img
                    src={"uploads/" + item.user_image}
                    alt="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <div className="verify-icon">
                    <i class="bx bx-check"></i>
                  </div>
                </div>
                <div className="notification-view-content-block">
                  <p className="content">
                    <span className="bold"></span> {item.description}
                  </p>
                  {/* <p className="content time">{item.created_at}</p> */}

                  <p className="small-content time">
                    <TimeAgo datetime={item.created_at} />
                  </p>
                </div>
              </div>
              {item.description.includes("moderator") ? (
                <div className="btn-block">
                  {item.moderator_status === "pending" ? (
                    <>
                      {" "}
                      <button
                        name="accept"
                        disabled={acceptLoad}
                        onClick={() => {
                          item.debate_id === null
                            ? moderatorAccept(item.user_front_id)
                            : debateaccept(item.debate_id);
                        }}
                        className="main-btn"
                      >
                        {acceptLoad?"....":"Accept"}
                      </button>
                      <button
                      disabled={rejectLoad}
                        name="reject"
                        onClick={() => {
                          item.debate_id === null
                            ? moderatorReject(item.user_front_id)
                            : debatereject(item.debate_id);
                        }}
                        className="main-btn reject"
                      >
                        {rejectLoad?"....":"Reject"}
                       
                        </button>{" "}
                    </>
                  ) : item.moderator_status === "accepted" ? (
                    <button name="accept" className="main-btn">
                      Accepted
                    </button>
                  ) : (
                    <button name="reject" className="main-btn reject">
                      Rejected
                    </button>
                  )}
                </div>
              ) : (
                // <div className="btn-block">
                //   <button
                //     name="accept"
                //     onClick={() => {
                //       item.debate_id === null
                //         ? moderatorAccept(item.user_front_id)
                //         : debateaccept(item.debate_id);
                //     }}
                //     className="main-btn"
                //   >
                //     Accept
                //   </button>

                //   <button
                //     name="reject"
                //     onClick={() => {
                //       item.debate_id === null
                //         ? moderatorReject(item.user_front_id)
                //         : debatereject(item.debate_id);
                //     }}
                //     className="main-btn reject"
                //   >
                //     Reject
                //   </button>
                // </div>
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default NotificationView;
