import React, { useEffect, useState } from "react";
import { TabComponent } from "../TabComponent/TabComponent";
import { GetContributorDetails, GetContributorProfile } from "../Api/GetApi";
import { defaultImage } from "../Utilities/Utils";

const ContributorShowPage = (props) => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (window.location.search.length > 0) {
      var id = window.location.href.split("=")[1];
    }
    GetContributorProfile(id).then((res) => {
      setData(res.data);
      console.log(res);
    });
    GetContributorDetails(id).then((res) => {
      setDetails(res.data);
    });
  }, []);

  const title = ["Debates", "For", "Against"];

  return (
    <div className="profile">
      <div className="profile-section">
        <p className="large-title">View Profile</p>

        {data?.map((item, index) => {
          return (
            <div className="block-card" key={index}>
              <div className="profile-content">
                <div className="row">
                  <div className="col-md-5 col-lg-5 profile-info">
                    <div className="d-flex">
                      <div className="img-content">
                        <img
                          src={"uploads/" + item.image}
                          alt="img"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = defaultImage;
                          }}
                        />
                      </div>
                      <div className="info-content">
                        <h3 className="content">
                          {item.name}
                          {item.points >= 10 ? (
                            <span className="debate-text">
                              <img
                                src={require("../../../assets/images/top.png")}
                                alt="img"
                              />
                              Top Debator
                            </span>
                          ) : (
                            ""
                          )}
                        </h3>

                        <p className="profile-sub-text">{item.email}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="d-flex sub-content">
                      <img src={require("../../../assets/images/medal.png")} />
                      <div className="profile-points">
                        <p className="profile-sub-text">Available Points</p>
                        <h3 className="content">{item.points}</h3>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3 col-lg-3">
                    <div className="d-flex sub-content">
                      <img src={require("../../../assets/images/cake .png")} />
                      <div className="profile-points">
                        <p className="profile-sub-text">Cake Day</p>
                        <h3 className="content">{item.dob}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {/* <div className="block-card mt-10"> */}
        <div className="block-card mt-10">
          <TabComponent title={title} type="showModerator" data={details} />
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ContributorShowPage;
