import React, { useState, useEffect, useContext } from "react";
import { DeleteModeratorApi } from "../Api/DeleteApi";
import { GetModeratorList } from "../Api/GetApi";
import { Customtable } from "../CustomTable/CustomTable";
import { PopUp } from "../PopUp/PopUp";
import "./ModeratorSinglePage.scss";
import { postModeratorBlock } from "../Api/PostApi";
import { ToasterProvider } from "../Context/ToasterContext";
import { ToasterContext } from "../Context/ToasterContext";

const ModeratorSinglePage = () => {
  const [visible, setVisible] = useState(false);
  const [moderatorData, setModeratorData] = useState([]);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isCreated, setIsCreated] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  useEffect(() => {
    GetModeratorList().then((response) => {
      setModeratorData(response.data);
    });
  }, [isDeleted, isCreated]);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  const updateDOM = () => {
    setIsCreated(!isCreated);
    setVisible(false);
  };

  const deleteModerator = (id) => {
    DeleteModeratorApi(id).then((res) => {
      if (res.data.status === 200) {
        setIsDeleted(true);
        successMessage("Moderator successfully deleted!");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const blockModerator = (id) => {
    postModeratorBlock(id).then((res) => {
      if (res.data.status === 200) {
        successMessage("Moderator successfully Blocked!");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  return (
    <div className="moderator-single-page">
      <div className="block-card  single-page-height">
        <div className="title-block">
          <h2 className="large-title primary-color">Moderators</h2>
          <button className="main-btn" onClick={() => openModal()}>
            <i className="bx bxs-plus-circle"></i>Create new moderator
          </button>
        </div>

        {visible && <PopUp
          visible={visible}
          type="createModerator"
          closeModal={() => closeModal()}
          updateDOM={updateDOM}
          title="Create New Moderator"
        />}

        <div className="moderator-single-table">
          <Customtable
            data={moderatorData}
            action={deleteModerator}
            blockModerator={blockModerator}
            closeModal={() => closeModal()}
            updateDOM={updateDOM}
            type="moderator"
          />
        </div>
      </div>
    </div>
  );
};

export default function ModeratorWithContext() {
  return (
    <ToasterProvider>
      <ModeratorSinglePage />
    </ToasterProvider>
  );
}
