//@ts-check
import React, { useState } from "react";

const REASONS = [
  "spam",
  "Hate speech ",
  "Bullying or harrasment",
  "Scam or fraud",
  "Voilence",
];

export const RejectPopUp = (props) => {
  /**@type {[string[], React.Dispatch<React.SetStateAction<string[]>>]} */
  const [rejectionReasons, setRejectionReasons] = useState([]);

  const [anotherReason, setAnotherReason] = useState("");

  /**
   *
   * @param {string} str?
   */
  const toggleReason = (str) => {
    const index = rejectionReasons.findIndex((item) => item === str);
    const clonedReasons = [...rejectionReasons];

    if (index === -1) {
      clonedReasons.push(str);
    } else {
      clonedReasons.splice(index, 1);
    }
    setRejectionReasons(clonedReasons);
  };

  const calculateClassName = (str) => {
    const classes = ["transparent-label-btn"];
    if (rejectionReasons.includes(str)) {
      classes.push("active-label-btn");
    }
    return classes.join(" ");
  };

  return (
    <div className="reject-popup ">
      <div className="reject-title-block">
        <h2 className="title">Reject</h2>
        <h4 className="block-title">Why are you rejecting this debate?</h4>
      </div>
      <div className="label-btn-block">
        {REASONS.map((item) => {
          return (
            <button
              key={item}
              className={calculateClassName(item)}
              onClick={() => {
                toggleReason(item);
              }}
            >
              {item}
            </button>
          );
        })}
      </div>
      <div className="input-content-block">
        <p className="small-content">Something else...</p>
        <textarea
          className="input-field"
          placeholder="Enter your reason to reject the debate"
          // disabled={rejectionReasons.length > 0}
          onChange={(e) => {
            setAnotherReason(e.target.value);
          }}
        />
      </div>
      <div className="btn-block">
        <button className="secondary-btn" onClick={props.closeModal}>
          Cancel
        </button>
        <button
          className="red-btn"
          onClick={() => {
            props.action(props.id, [...rejectionReasons, anotherReason]);
            props.closeModal();
          }}
          disabled={
            rejectionReasons.length === 0 && anotherReason.length === 0
              ? true
              : false
          }
        >
          Reject
        </button>
      </div>
    </div>
  );
};
