//@ts-check
import React, { useState, useEffect, useContext } from "react";
import { postModerator, postModeratorUpdate } from "../Api/PostApi";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./PopUp.scss";
import * as Yup from "yup";
import {
  GetModeratorCategoryList,
  GetModeratorCountryList,
} from "../Api/GetApi";
import { ToasterContext } from "../Context/ToasterContext";
import { defaultImage } from "../Utilities/Utils";

export const CreateModeratorPopUp = (props) => {
  /**@type {"createModerator" | "editmoderator"} */
  const type = props.type;
  const [image, setImage] = useState(defaultImage);
  const [countryList, setCountryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  useEffect(() => {
    const initialImage = props.itemObj?.image
      ? `${props.itemObj?.image}`
      : defaultImage;
    setImage(initialImage);
  }, [props.itemObj]);

  useEffect(() => {
    console.log({ image });
  }, []);

  const fetchData = async () => {
    const [resp1, resp2] = await Promise.all([
      GetModeratorCountryList(),
      GetModeratorCategoryList(),
    ]);
    setCountryList(resp1.data);
    setCategoryList(resp2.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="create-moderator-popup">
      <h2 className="title">{props?.title}</h2>
      <div className="moderator-popup-section scroll-bar">
        <Formik
          enableReinitialize
          initialValues={{
            name: props.itemObj?.name,
            password: "",
            email: props.itemObj?.email,
            country: props.itemObj?.country,
            image: props.itemObj?.image,
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(2, "Must be 2 characters or more")
              .required("Required"),
            password: Yup.string()
              .min(6, "Must be 6 characters or more")
              .required("Required"),
            email: Yup.string()
              .email("Invalid email addresss`")
              .required("Required"),

            country: Yup.string().required("Required"),
            image: Yup.string().required("Required"),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(true);
            var formData = new FormData();
            formData.append("name", values.name);
            formData.append("password", values.password);
            formData.append("email", values.email);
            formData.append("country", values.country);
            formData.append("image", values.image);
            formData.append("user_type", "moderator");
            const apiCall =
              type === "createModerator"
                ? postModerator(formData)
                : postModeratorUpdate(formData, props.itemObj.id);
            apiCall.then((res) => {
              if (res.data.status === 200) {
                {
                  props.closeModal();
                  if (type === "editmoderator") {
                    successMessage("Moderator updated successfully !");
                  } else {
                    successMessage("Moderator created successfully !");
                  }
                  resetForm();
                }

                // alert("success");
                resetForm({
                  values: {
                    name: "",
                    password: "",
                    email: "",
                    country: "",
                    image: "",
                  },
                });
                setImage("http://i.pravatar.cc/500?img=7");
                props.updateDOM();
                setSubmitting(false);
              } else {
                errorMessage(res.data.message);
                setSubmitting(false);
              }
            });
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="input-content-block">
                  <label className="content">Image</label>

                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        name="image"
                        type="file"
                        id="imageUpload"
                        accept="image/*"
                        onChange={(e) => {
                          const image = e.target.files[0];
                          const url = URL.createObjectURL(image);
                          setImage(url);
                          console.log({ url });
                          formik.setFieldValue("image", image);
                        }}
                      />
                      <label htmlFor="imageUpload">
                        <i className="bx bxs-pencil"></i>
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: `url(${image})`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <span className="error">
                    <ErrorMessage name="image" />
                  </span>
                </div>
                <div className="input-content-block">
                  <label className="content">Name</label>
                  <Field
                    name="name"
                    type="text"
                    placeholder="Enter name"
                    className="input-field"
                  />
                  <span className="error">
                    <ErrorMessage name="name" />
                  </span>
                </div>
                <div className="input-content-block">
                  <label className="content">Password</label>
                  <Field
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    className="input-field"
                  />
                  <span className="error">
                    <ErrorMessage name="password" />
                  </span>
                </div>
                <div className="input-content-block">
                  <label className="content">Email</label>
                  <Field
                    name="email"
                    type="email"
                    placeholder="Enter email address"
                    className="input-field"
                  />
                  <span className="error">
                    <ErrorMessage name="email" />
                  </span>
                </div>
                {/* <div className="input-content-block">
                  <label className="content">Category </label>
                  <Field name="categoryid" className="input-field" as="select">
                    <option>Select Category</option>
                    {categoryList?.map((item, index) => {
                      return (
                        <option value={item.value} key={index}>
                          {item.category_name}
                        </option>
                      );
                    })}
                  </Field>
                  <span className="error">
                    <ErrorMessage name="categoryid" />
                  </span>
                </div> */}
                <div className="input-content-block">
                  <label className="content">Country</label>
                  <Field name="country" as="select" className="input-field">
                    <option>Select Country</option>
                    {countryList.map((list, index) => {
                      return (
                        <option value={list} key={index}>
                          {list}
                        </option>
                      );
                    })}
                    <option value="nepal">Nepal</option>
                    <option value="india">Indial</option>
                    <option value="china">China </option>
                  </Field>
                  <span className="error">
                    <ErrorMessage name="country" />
                  </span>
                </div>

                <button
                  disabled={formik.isSubmitting}
                  type="submit"
                  className="main-btn"
                >
                  {" "}
                  Submit
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
