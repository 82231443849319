import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import ImageDropzone from "./ImageDropzone";
import SubCategory from "./SubCategory";
import * as Yup from "yup";
import "./CategoriesCreateNew.scss";
import { postCatergoryCreate, postCatergoryEdit } from "../Api/PostApi";
import { ToasterContext, ToasterProvider } from "../Context/ToasterContext";
import EditHelperFormik from "./EditHelperFormik";

const CategoriesCreateNew = (props) => {
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const [subCategory, setSubCategory] = useState(false);
  const [actionType, setActionType] = useState("create");
  const [categoryId, setcategoryId] = useState(null);
  const category = props.category?.category;
  const sub_category = props.category?.sub_category;

  useEffect(() => {
    if (props.category !== null) {
      setSubCategory(true);
    }
    if (window.location.search.length > 0) {
      const url = new URL(window.location.href);
      const isEditMode = url.searchParams.get("mode") === "edit";
      if (isEditMode) {
        setActionType("edit");
      }
      setcategoryId(window.location.href.split("=")[1].split("&")[0]);
    }
  }, []);

  return (
    <div className="create-new-category">
      <div className="block-card">
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: "",
            image: [],
            color: "",
            hasSubcategory: false,
            subCategories: [],
          }}
          validationSchema={Yup.object().shape({
            title: Yup.string().required("Required"),
            color: Yup.string().required("Required"),
            image: Yup.array()
              .of(
                Yup.object()
                  .shape({
                    name: Yup.string,
                  })
                  .nullable()
              )
              .min(1, "at least one image is required")
              .required("Required"),
            subCategories: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required("Required"),
                image: Yup.array()
                  .of(
                    Yup.object()
                      .shape({
                        name: Yup.string,
                      })
                      .nullable()
                  )
                  .min(1, "at least one image is required")
                  .required("Required"),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            try {
              setSubmitting(true);
              var formData = new FormData();

              formData.append("category_name", values.title);
              formData.append("color_code", values.color);
              formData.append("image", values.image[0]);
              formData.append("hasSubcategory", values.hasSubcategory);
              values.subCategories.forEach((item, index) => {
                formData.append(`subcategories[${index}][title]`, item.title);
                formData.append(
                  `subcategories[${index}][image]`,
                  item.image[0]
                );
                formData.append(`subcategories[${index}][id]`, item.id);
              });
              const apiCall =
                actionType === "create"
                  ? postCatergoryCreate
                  : postCatergoryEdit;
              apiCall(formData, categoryId).then((res) => {
                setSubmitting(false);

                if (res.data.status === 200) {
                  {
                    successMessage(
                      actionType === "create"
                        ? "Categories created successfully !"
                        : "Categories updated successfully !"
                    );
                    window.location =
                      window.location.origin + "/category_section";
                  }
                  // alert("success");
                  resetForm({
                    values: {
                      title: "",
                      color: "",
                      image: "",
                      has_sub_group: "",
                    },
                  });
                  props.updateDOM();
                } else {
                  setSubmitting(false);
                  errorMessage(res.data.message);
                }
              });
              setSubmitting(false);
            } catch (error) {
              console.error(error);
            }
          }}
        >
          {({ values, setFieldValue, errors, handleSubmit }) => {
            return (
              <Form>
                {actionType === "edit" && (
                  <EditHelperFormik
                    categories={category}
                    subCategories={sub_category}
                  />
                )}
                <div className=" createnew-categories">
                  <div className="category-section">
                    <div className="title-block">
                      <h2 className="large-title primary-color">
                        {actionType === "edit"
                          ? "Edit Category"
                          : "Create New Category"}
                      </h2>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-20">
                          <p className="sub-content">Category Name</p>

                          <Field
                            type="text"
                            name="title"
                            placeholder="Enter category name"
                            className="input-field"
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="error"
                          />
                        </div>

                        <div>
                          <p className="sub-content">Select Category Color</p>
                          <Field
                            type="color"
                            className="color-style"
                            name="color"
                          />
                          <ErrorMessage
                            name="color"
                            component="div"
                            className="error"
                          />
                        </div>

                        {actionType === "edit" &&
                        values.hasSubcategory === false ? (
                          <></>
                        ) : (
                          <div className="checkbox-flex">
                            <ul class="unstyled centered">
                              <li>
                                <Field
                                  name="hasSubcategory"
                                  class="styled-checkbox"
                                  id="styled-checkbox-1"
                                  type="checkbox"
                                />
                                <label for="styled-checkbox-1">
                                  has sub category
                                </label>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <p className="sub-content">Choose Category image</p>

                        <ImageDropzone
                          files={values["image"]}
                          setFiles={(files) => {
                            setFieldValue("image", files);
                          }}
                        />
                        {/* {errors["image"] && (
                        <div className="error">{errors["image"]}</div>
                        )} */}
                        <ErrorMessage
                          name="image"
                          component="div"
                          className="error"
                        />
                      </div>
                    </div>
                  </div>

                  {values["hasSubcategory"] && (
                    <FieldArray name="subCategories">
                      {({ remove, push }) => {
                        return (
                          <div>
                            <div className="sub-category d-flex">
                              <p className="sub-content ft-20">Sub Category</p>
                              {actionType === "edit" ? (
                                <></>
                              ) : (
                                <button
                                  className="main-btn"
                                  type="button"
                                  onClick={() => {
                                    push({
                                      title: "",
                                      image: [],
                                      id: "",
                                    });
                                  }}
                                >
                                  Add new sub-category
                                </button>
                              )}
                            </div>

                            {values["subCategories"].map((item, index) => {
                              return (
                                <div
                                  className="sub-category-rel"
                                  style={{
                                    marginBottom: 20,
                                  }}
                                >
                                  <SubCategory
                                    key={index}
                                    index={index}
                                    name="subCategories"
                                  />
                                  <button
                                    className="btn-remove"
                                    type="button"
                                    onClick={() => {
                                      remove(index);
                                    }}
                                  >
                                    <i class="bx bx-x"></i>
                                  </button>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }}
                    </FieldArray>
                  )}
                </div>
                <button
                  onClick={handleSubmit}
                  className="create-btn main-btn"
                  type="submit"
                >
                  {actionType === "edit" ? "Edit" : "Create"}
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default function CategoriesCreateWithContext(props) {
  return (
    <ToasterProvider>
      <CategoriesCreateNew {...props} />
    </ToasterProvider>
  );
}
