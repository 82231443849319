/**
 * @param {string} url
 */
export async function createFile(url) {
  let response = await fetch(url);
  let data = await response.blob();

  let metadata = {
    type: "image/jpeg",
  };
  const urlArr = url.split("/");
  const name = urlArr[urlArr.length - 1];
  let file = new File([data], name, metadata);
  console.log(file);
  return file;
}
