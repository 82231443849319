import React from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const TabPanelsComponent = () => {
  return (
    <Tabs>
      <TabList>
        <Tab> Debates</Tab>
        <Tab>For</Tab>
        <Tab>Against</Tab>
        <Tab>Undecided</Tab>
        <Tab>Agreed</Tab>
        <Tab> Disagreed</Tab>
      </TabList>

      <TabPanel>
        <p> content 1</p>
      </TabPanel>
      <TabPanel>
        <p> content 2</p>
      </TabPanel>
    </Tabs>
  );
};

export default TabPanelsComponent;
