import React, { useState, useEffect } from "react";
import { GetDebates } from "../Api/GetApi";
import { TabComponent } from "../TabComponent/TabComponent";
import { ToasterProvider } from "../Context/ToasterContext";

const DebatesPage = (props) => {
  const title = ["Pending Debates", "Approved Debates", "Rejected Debates"];
  const [categoryList, setcategoryList] = useState([]);
  const [data, setData] = useState([]);
  const [updateDebate, setUpdateDebate] = useState(false);

  useEffect(() => {
    setcategoryList([]);
    GetDebates().then((response) => {
      setData(response.data.debate_data);
      setcategoryList(response.data.category);
    });
  }, [updateDebate]);

  return (
    <div className="debate-page">
      <div className="block-card">
        <h1 className="large-title primary-color">Debates</h1>
        {categoryList.length > 0 && (
          <TabComponent
            title={title}
            categoryList={categoryList}
            data={data}
            type="debate"
            setUpdateDebate={setUpdateDebate}
            updateDebate={updateDebate}
          />
        )}
      </div>
    </div>
  );
};

export default function DebatesWithContext() {
  return (
    <ToasterProvider>
      <DebatesPage />
    </ToasterProvider>
  );
}
