import React, { useEffect, useState } from "react";
import { GetPendingStatement } from "../Api/GetApi";
import { ToasterProvider } from "../Context/ToasterContext";
import { Debators } from "../Debtors/Debtors";
import { InfoCard } from "../InfoCard/InfoCard";
import { PieChart } from "../PieChart/PieChart";
import { Statement } from "../Statement/Statement";

import { PendingDebates } from "./PendingDebates/PendingDebates";

const Index = (props) => {
  const [pendingStatement, setPendingStatement] = useState([]);
  const [updateStatement, setUpdateStatement] = useState(false);

  const hydrateStatement = () => {
    GetPendingStatement().then((res) => {
      setPendingStatement(res.data.pending_statement);
    });
  };

  useEffect(() => {
    hydrateStatement();
  }, [updateStatement]);

  return (
   
    <ToasterProvider>
      <div className="dashboard moderator-dashboard">
        <div className="title-block">
          <h2 className="large-title primary-color">Dashboard{props.data}</h2>
          <p className="small-content">
            You can approve the pending debates now.
          </p>
        </div>
        <div className="row">
          <div className="col-md-5">
            <InfoCard />
            <div className="section-gap">
              <PendingDebates />
            </div>
          </div>
          <div className="col-md-7">
            <div className="row">
              <div className="col-md-5 p-0">
                <PieChart />
              </div>
              <div className="col-md-7">
                <div className="moderator-Debatorsscroll-bar">
                  <Debators />
                </div>
              </div>
              <div className="col-md-12">
                <div className="section-gap">
                  <Statement
                    type="moderator"
                    title="Pending statement"
                    statement={pendingStatement}
                    textLength={250}
                    hydrateStatement={hydrateStatement}
                    updateStatement={updateStatement}
                    setUpdateStatement={setUpdateStatement}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToasterProvider>
  );
};

export default Index;
