import React, { useEffect, useState } from "react";
import { GetModeratorDetails, GetModeratorProfile } from "../Api/GetApi";
import { TabComponent } from "../TabComponent/TabComponent";
import "./ShowModerator.scss";
import { defaultImage } from "../Utilities/Utils";

const ShowModerator = (props) => {
  const [data, setData] = useState([]);
  const [details, setDetails] = useState([]);
  const title = [
    "Approved Debates",
    "Approved Statements",
    "Rejected Debates",
    "Rejected Statements",
  ];
  useEffect(() => {
    if (window.location.search.length > 0) {
      var id = window.location.href.split("=")[1];
    }
    GetModeratorProfile(id).then((res) => {
      setData(res.data);
    });
    GetModeratorDetails(id).then((res) => {
      setDetails(res.data);
    });
  }, []);

  return (
    <div className="profile">
      <div className="show-moderator">
        {/* <Profile data={data} /> */}
        <div className="profile-section">
          <p className="large-title">View Profile</p>

          {data?.map((item, index) => {
            return (
              <div className="block-card" key={index}>
                <div className="profile-content">
                  <div className="row">
                    <div className="col-md-5 col-lg-5 profile-info">
                      <div className="d-flex">
                        <div className="img-content img-uploads">
                          <img
                            src={"uploads/" + item.image}
                            alt="img"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                        </div>
                        <div className="info-content">
                          <h3 className="content">
                            {item.name}
                            {item.points >= 10 ? (
                              <span className="debate-text">
                                <img
                                  src={require("../../../assets/images/top.png")}
                                  alt="img"
                                />
                                Top Debator
                              </span>
                            ) : (
                              ""
                            )}
                          </h3>

                          <p className="profile-sub-text">{item.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <div className="d-flex sub-content">
                        <img
                          src={require("../../../assets/images/medal.png")}
                        />
                        <div className="profile-points">
                          <p className="profile-sub-text">Available Points</p>
                          <h3 className="content">{item.points}</h3>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-lg-3">
                      <div className="d-flex sub-content">
                        <img
                          src={require("../../../assets/images/cake .png")}
                        />
                        <div className="profile-points">
                          <p className="profile-sub-text">Cake Day</p>
                          <h3 className="content">{item.dob}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="block-card mt-10">
        <TabComponent title={title} type="showModerator" data={details} />
      </div>
    </div>
  );
};

export default ShowModerator;
