import React, { useState, useContext, useEffect } from "react";
import TimeAgo from "timeago-react";
import parse from "html-react-parser";
import "./Statement.scss";
import { ToasterContext } from "../Context/ToasterContext";
import { postStatementAccept, postStatementReject } from "../Api/PostApi";
import { PopUp } from "../PopUp/PopUp";
import NotFoundPage from "../notFoundPage/notFoundPage";
import { defaultImage } from "../Utilities/Utils";
import { getStatement } from "../Api/GetApi";
import { Loader } from "../Loader/Loader";
import { DeleteStatementApi } from "../Api/DeleteApi";

export const Statement = (props) => {
  const [statement, setStatement] = useState([]);
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const [visible, setVisible] = useState(false);
  const [statementId, setStatementId] = useState("");
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [removeStatement, setRemoveStatement] = useState(false);
  const [showMore, setShowMore] = useState({});
  const [tabIndexStatement, setTabIndexStatement] = useState(null);
  const [userType, setUserType] = useState("");
  const [userFront, setUserFront] = useState("");
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    setStatement(props.statement);
  }, [props.statement]);

  useEffect(() => {
    getStatement().then((res) => {
      setUserType(res.data.user_type);
      setUserFront(res.data.user);
      setIsLoading(false);
    });
  }, [userType, userFront]);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  const acceptStatement = (id) => {
    var postData = {
      comment_id: id,
    };
    postStatementAccept(postData).then((res) => {
      if (res.data.status === 200) {
        setVisible(false);
        props.setUpdateStatement(!props.updateStatement);
        successMessage(res?.data?.message);
      } else {
        closeModal();
        errorMessage(res?.data?.message);
      }
    });
  };

  const rejectStatement = (id) => {
    var postData = {
      comment_id: id,
    };
    postStatementReject(postData).then((res) => {
      if (res.data.status === 200) {
        props.setUpdateStatement(!props.updateStatement);
        closeModal();
        successMessage("Statement rejected successfully !");
        props.hydrateStatement();
      } else {
        closeModal();
        errorMessage(res.data.message);
      }
    });
  };
  const deleteStatement = (id) => {
    DeleteStatementApi(id).then((res) => {
      if (res.status === 200) {
        props.setUpdateStatement(!props.updateStatement);
        successMessage("Statements deleted successfully !");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const filterStatement = (name) => {
    const returnData =
      name === "All"
        ? props.statement
        : props.statement.filter((el) => el.category_name === name);
    setStatement(returnData);
  };

  const toggleText = (comment_id) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [comment_id]: !Boolean(prevShowMore[comment_id]),
    }));
  };

  return (
    <>
      <div className="statement section-gap-bottom">
        {props?.showCategory === "true" ? (
          <div className="label-btn-block">
            <button
              className={`label-btn ${
                tabIndexStatement === null ? "active-label-btn" : ""
              }`}
              onClick={() => {
                filterStatement("All");
                setTabIndexStatement(null);
              }}
            >
              All
            </button>
            {props.categoryList?.map((list, index) => {
              return (
                <button
                  className={`label-btn ${
                    index === tabIndexStatement ? "active-label-btn" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    filterStatement(list?.category_name);
                    setTabIndexStatement(index);
                  }}
                >
                  {list.category_name}
                </button>
              );
            })}
          </div>
        ) : (
          ""
        )}
        {isloading ? (
          <Loader />
        ) : (
          <>
            <div className="">
              <h2 className="title primary-color">{props?.title}</h2>
              <div className="statement-area  scroll-bar">
                {statement.length < 1 ? (
                  <NotFoundPage title={"pending statements"} />
                ) : (
                  <>
                    {statement?.map((item, index) => {
                      return (
                        <div
                          className="statement-section block-card "
                          key={index}
                        >
                          <div className="d-space-between">
                            <h5 className="stmt-title">{item.title}</h5>
                            {props.type !== "moderator" ? (
                              <div className="d-flex drop-section">
                                <label className="topic-label blue-label">
                                  {item.category_name}
                                </label>
                                {userType === "admin" && (
                                  <div className="drop-down">
                                    <div className="dropdown show">
                                      <a
                                        className="dots"
                                        id="dropdownMenuLink"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <i className="bx bx-dots-vertical-rounded dots-three"></i>
                                      </a>
                                      <div
                                        className="dropdown-menu action-block"
                                        aria-labelledby="dropdownMenuLink"
                                        aria-haspopup="true"
                                      >
                                        <a className="dropdown-item delete">
                                          <i
                                            className="bx bx-trash"
                                            onClick={() => {
                                              openModal();
                                              setRemoveStatement(true);
                                              setAccept(false);
                                              setReject(false);
                                              setStatementId(item?.comment_id);
                                            }}
                                          ></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="statement-top-block">
                            <div className="statement-profile">
                              <div className="statement-image">
                                <img
                                  src={item.user_image}
                                  alt="img"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultImage;
                                  }}
                                />
                              </div>

                              <div className="profile-info">
                                <h2 className="block-title">{item.name}</h2>
                                <p className="small-content">
                                  <TimeAgo datetime={item.created_at} />
                                </p>
                              </div>
                              <button
                                disabled
                                className={
                                  item.for_against === "For"
                                    ? "transparent-btn"
                                    : "transparent-btn  against-btn"
                                }
                              >
                                {item.for_against}
                              </button>
                            </div>
                            {props.type === "moderator" && (
                              <div className="btn-block mod-icon">
                                {item.user_front !== userFront ||
                                userType === "admin" ? (
                                  <>
                                    <button className="icon-btn mod-btn pri-icon">
                                      <i
                                        class="bx bx-check-circle"
                                        onClick={() => {
                                          openModal();
                                          setStatementId(item.comment_id);
                                          setAccept(true);
                                        }}
                                      ></i>
                                    </button>
                                    <button className="icon-btn del-icon">
                                      <i
                                        class="bx bx-x-circle"
                                        onClick={() => {
                                          openModal();
                                          setStatementId(item.comment_id);
                                        }}
                                      ></i>
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </div>
                            )}
                          </div>

                          <div className="statement-block">
                            <div className="row">
                              <div className="col-lg-10 col-md-10">
                                {props.type === "moderator" ? (
                                  ""
                                ) : (
                                  <>
                                    {/* <h5 className="stmt-title">{item.title}</h5> */}
                                    {item.key_argument && (
                                      <h2 className="key-argument">
                                        {item.key_argument}
                                      </h2>
                                    )}
                                  </>
                                )}

                                <div className="statement-description-block">
                                  {item.comment?.length === 0 ? (
                                    ""
                                  ) : (
                                    <p className="statement-description ">
                                      <span
                                        className={`content description ${
                                          showMore[item.comment_id]
                                            ? "show"
                                            : "hide"
                                        }`}
                                      >
                                        {parse(`${item.comment}`)}
                                      </span>
                                      {item.comment?.length >
                                        props?.textLength && (
                                        <span
                                          className="content read-more"
                                          onClick={() => {
                                            toggleText(item.comment_id);
                                          }}
                                        >
                                          {showMore[item.comment_id]
                                            ? " show less"
                                            : "read more"}
                                        </span>
                                      )}
                                    </p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-2 button-block">
                                <div className="btn-block-vertical">
                                  {props?.statementIndex === 0 ? (
                                    <>
                                      {item.user_front !== userFront ||
                                      userType === "admin" ? (
                                        <div className="btn-block-vertical">
                                          <button
                                            className="main-btn"
                                            onClick={() => {
                                              openModal();
                                              setStatementId(item.comment_id);
                                              setReject(false);
                                              setAccept(true);
                                              setRemoveStatement(false);
                                            }}
                                          >
                                            Accept
                                          </button>
                                          <button
                                            className="red-btn"
                                            onClick={() => {
                                              openModal();
                                              setStatementId(item.comment_id);
                                              setAccept(false);
                                              setReject(true);
                                              setRemoveStatement(false);
                                            }}
                                          >
                                            Reject
                                          </button>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {props?.statementIndex === 1 && (
                                    <>
                                      <div className="btn-block-vertical">
                                        {/* {userType === "admin" ? (
                                      <button name="accept" className="red-btn">
                                        Delete
                                      </button>
                                    ) : (
                                      ""
                                    )} */}
                                        <button
                                          name="accept"
                                          className="main-btn"
                                        >
                                          Accepted
                                        </button>
                                      </div>
                                    </>
                                  )}

                                  {props?.statementIndex === 2 && (
                                    <button name="accept" className="red-btn">
                                      Rejected
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          {props?.type === "moderator" ||
                          props.action === "true" ? (
                            ""
                          ) : (
                            <div className="btn-block">
                              <div className="action-block">
                                <button className="icon-btn">
                                  <i class="bx bxs-like"></i>
                                </button>
                                <span className="content">
                                  {item.like_count !== null
                                    ? item.like_count
                                    : "0"}
                                </span>
                              </div>
                              <div className="action-block">
                                <button className="icon-btn">
                                  <i class="bx bxs-dislike"></i>
                                </button>
                                <span className="content">
                                  {item.dislike !== null ? item.dislike : "0"}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {visible && (
          <PopUp
            visible={visible}
            type={
              accept
                ? "confirmation"
                : reject
                ? "reject"
                : removeStatement
                ? "confirmation"
                : ""
            }
            // type="confirmation"
            closeModal={() => closeModal()}
            action={
              accept
                ? acceptStatement
                : reject
                ? rejectStatement
                : removeStatement
                ? deleteStatement
                : ""
            }
            id={statementId}
          />
        )}
      </div>
    </>
  );
};
