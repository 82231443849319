import React, { useState, useEffect, useContext } from "react";
import { Customtable } from "../CustomTable/CustomTable";
import { AreaChart } from "../AreaChart/AreaChart";
import { TabComponent } from "../TabComponent/TabComponent";
import "./ContributorSinglePage.scss";
import { GetContributors } from "../Api/GetApi";
import { DeleteContributorApi } from "../Api/DeleteApi";

import { ToasterProvider } from "../Context/ToasterContext";
import { ToasterContext } from "../Context/ToasterContext";

const ContributorSinglePage = () => {
  const title = ["Top Debators", "Debators"];
  const tabPanelComponents = [Customtable, AreaChart];
  const [data, setData] = useState([]);
  const [updatecontributor, setUpdateContributor] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  useEffect(() => {
    GetContributors().then((res) => {
      setData(res.data);
    });
  }, [updatecontributor]);

  const deleteContributor = (id) => {
    DeleteContributorApi(id).then((res) => {
      if (res.data.status === 200) {
        successMessage("Debtor successfully deleted!");
        setUpdateContributor(!updatecontributor);
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const updateDOM = () => {
    setUpdateContributor(!updatecontributor);
  };

  return (
    <div className="contributor-single-page">
      <div className="block-card">
        <h2 className="large-title primary-color">Contributors</h2>
        <TabComponent
          title={title}
          type="contributor"
          tabPanelComponents={tabPanelComponents}
          data={data}
          action={deleteContributor}
          updateDOM={updateDOM}
        />
      </div>
    </div>
  );
};

export default function ContributorWithContext() {
  return (
    <ToasterProvider>
      <ContributorSinglePage />
    </ToasterProvider>
  );
}
