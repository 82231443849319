import Axios from "axios";

export const GetModeratorList = () => {
  return Axios.get("/backend_api/moderators_list");
};

export const GetModeratorEdit = (id) => {
  return Axios.get(`/backend_api/moderator/edit/${id}`);
};

export const GetTopDebators = () => {
  return Axios.get("/backend_api/top_debators");
};

export const GetPopularDebates = () => {
  return Axios.get("/backend_api/popular_debate");
};

export const GetPopularCategory = () => {
  return Axios.get("/backend_api/popular_category");
};

export const GetDebates = () => {
  return Axios.get("/backend_api/debate_status");
};

// export const GetAdminNotifications = () => {
//   return Axios.get("backend_api/admin/notification");
// };
// export const GetModeratorNotifications = () => {
//   return Axios.get("backend_api/moderator/notification");
// };
export const GetDebatesCategoryList = () => {
  return Axios.get("/backend_api/category_list");
};

export const GetModeratorCountryList = () => {
  return Axios.get("/backend_api/country_list");
};

export const GetModeratorCategoryList = () => {
  return Axios.get("/backend_api/category_list");
};

export const GetPopularStatement = () => {
  return Axios.get("/backend_api/popular_statement");
};
export const GetPendingStatement = () => {
  return Axios.get("/backend_api/pending_statement");
};
export const getStatement = () => {
  return Axios.get("/backend_api/statement_status ");
};

export const GetContributors = () => {
  return Axios.get("/backend_api/contributor_list");
};

export const GetCategories = () => {
  return Axios.get("/backend_api/all_category");
};

export const GetPendingDebate = () => {
  return Axios.get("/backend_api/pending_debate");
};

export const GetCount = () => {
  return Axios.get("/backend_api/accepted_rejected_count");
};

export const GetQueries = () => {
  return Axios.get("/backend_api/contact_us");
};

export const GetModeartorPerformance = () => {
  return Axios.get("/backend_api/moderator_performance");
};

export const GetUserCounts = () => {
  return Axios.get("/backend_api/total_user/count");
};

export const GetAreaChartData = () => {
  return Axios.get("/backend_api/user_visit_chart");
};

export const GetTotalModerator = () => {
  return Axios.get("/backend_api/total_moderator/count");
};

export const GetTotalVisitor = () => {
  return Axios.get("/backend_api/total_user/count");
};

export const GetContributorProfile = (id) => {
  return Axios.get(`/backend_api/contributor_profile/${id}`);
};

export const GetModeratorProfile = (id) => {
  return Axios.get(`/backend_api/moderator_profile/${id}`);
};

export const GetContributorDetails = (id) => {
  return Axios.get(`/backend_api/contributor_debate_detail/${id}`);
};

export const GetModeratorDetails = (id) => {
  return Axios.get(`/backend_api/moderator_accepted_rejected_details/${id}`);
};

//Notification

export const GetAdminNotifications = () => {
  return Axios.get("/backend_api/admin/notification");
};

export const GetModeratorNotifications = () => {
  return Axios.get("/backend_api/moderator/notification");
};
