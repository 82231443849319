import React, { useEffect, useState } from "react";
import { GetTotalModerator, GetTotalVisitor } from "../Api/GetApi";
import "./InfoCard.scss";

export const InfoCard = () => {
  const [moderatorCount, setModeratorCount] = useState([]);
  const [visitorCount, setVisitorCount] = useState([]);
  useEffect(() => {
    GetTotalModerator().then((res) => {
      setModeratorCount(res.data[0]?.total_moderator);
    });
    GetTotalVisitor().then((res) => {
      setVisitorCount(res.data?.total_user[0]?.count);
    });
  }, []);
  return (
    <div className="info-card">
      <div className="info-card-section">
        <div className="bg-shape circle1"></div>
        <div className="bg-shape circle2"></div>
        <div className="info-card-image">
          <img
            src={require("../../../assets/images/audience.png")}
            alt="icon"
          />
        </div>
        <div className="info-card-total">
          <div>
            <p className="content">Total Visitors</p>
            <h2 className="total-number">
              {/* {props.infoCardData.total_visit_count} */}
              {visitorCount}
            </h2>
          </div>
          <p className="total-percentage content">2.5%</p>
        </div>
      </div>
      <div className="info-card-section">
        <div className="bg-shape circle1"></div>
        <div className="bg-shape circle2"></div>
        <div className="info-card-image">
          <img
            src={require("../../../assets/images/programmer.png")}
            alt="icon"
          />
        </div>
        <div className="info-card-total">
          <div>
            <p className="content">Total Moderator</p>
            <h2 className="total-number">{moderatorCount}</h2>
          </div>
          <p className="total-percentage content">2.5%</p>
        </div>
      </div>
    </div>
  );
};
