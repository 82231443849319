import React, { useEffect, useState } from "react";
import { AreaChart } from "../AreaChart/AreaChart";
import { Debates } from "../Debates/Debates";
import { Debators } from "../Debtors/Debtors";
import { DonutChart } from "../DonutChart/DonutChart";
import Moderators from "../Moderators/Moderators";
import { ModeratorsPerformance } from "../ModeratorsPerformance/ModeratorsPerformance";
import { Statement } from "../Statement/Statement";
import { WorldMapComponent } from "../WorldMap/WorldMap";

import { ToasterProvider } from "../Context/ToasterContext";
import { GetPopularStatement } from "../Api/GetApi";
import { Loader } from "../Loader/Loader";

const Index = (props) => {
  const [popularStatement, setpopularStatement] = useState([]);

  useEffect(() => {
    GetPopularStatement().then((res) => {
      setpopularStatement(res.data);
    });
  }, []);
  return (
    <ToasterProvider>
      <div className="dashboard">
        <h2 className="large-title primary-color">Dashboard</h2>
        <div className="section-gap-bottom">
          <div className="row">
            <div className="col-md-8 pr-0">
              <AreaChart />
              <ModeratorsPerformance />
            </div>
            <div className="col-md-4">
              <Moderators moderators={props.moderators} />
              <div className="section-gap-top">
                <Debators />
              </div>
            </div>
          </div>
        </div>
        <div className="section-gap">
          <div className="row">
            <div className="col-md-5 pr-0">
              <DonutChart />
            </div>
            <div className="col-md-7">
              <Debates />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Statement
              title="Popular Statement"
              statement={popularStatement?.slice(0, 5)}
            />
          </div>
          <div className="col-md-12">
            <WorldMapComponent />
          </div>
        </div>
      </div>
      <Loader/>
    </ToasterProvider>
  );
};

export default Index;
