import React, { useContext, useEffect, useState } from "react";
import { GetPendingDebate } from "../../Api/GetApi";
import { postDebateAccept, postDebateReject } from "../../Api/PostApi";
import { ToasterContext } from "../../Context/ToasterContext";
import "./PendingDebates.scss";
import { PopUp } from "../../../components/PopUp/PopUp";
import NotFoundPage from "../../notFoundPage/notFoundPage";
import { Loader } from "../../Loader/Loader";

export const PendingDebates = () => {
  const [pendingDebate, setPendingDebate] = useState([]);
  const [updateDebate, setUpdateDebate] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const [visible, setVisible] = useState(false);
  const [debateId, setDebateId] = useState("");
  const [accept, setAccept] = useState(false);
  const [userType, setUserType] = useState("");
  const [userFront,setUserFront] = useState();
  const [isloading,setIsLoading] = useState(true);

  useEffect(() => {
    GetPendingDebate().then((res) => {
      setPendingDebate(res.data.pending_debate);
      setUserType(res.data.user_type);
      setUserFront(res.data.user);
      setIsLoading(false);
      console.log("duserType", userType);
      console.log("duserFront", userFront);
    });
  }, [updateDebate,userType,userFront]);

  const acceptDebate = (id) => {
    var postData = {
      debate_id: id,
    };
    postDebateAccept(postData).then((res) => {
      setUpdateDebate(false);
      if (res.data.status === 200) {
        successMessage(res?.data?.message);
        setUpdateDebate(true);
      } else {
        errorMessage(res?.data?.message);
      }
    });
  };

  const rejectDebate = (id) => {
    var postData = {
      debate_id: id,
    };
    postDebateReject(postData).then((res) => {
      setUpdateDebate(false);
      if (res.data.status === 200) {
        successMessage(res?.data?.message);
        setUpdateDebate(true);
      } else {
        errorMessage(res?.data?.message);
      }
    });
  };

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };
  return (
    <>
    {isloading ?(
      <Loader/>
    ):(
    <div className="pending-debates ">
      <div className="section-card">
        <h2 className="title primary-color title-border">Pending Debates</h2>
        <div className="pending-debate-section scroll-bar">
          {pendingDebate.length < 1 ? (
            <NotFoundPage title={"pending debates"} />
          ) : (
            <>
              {pendingDebate.map((item, index) => {
                console.log("dpendingDebate",pendingDebate)
                return (
                  <div className="pending-debates-block" key={index}>
                    <p className="content">{item.title}</p>
                    {((item.user_front_id !== userFront ) || (userType==="admin"))&& (<div className="action-block">
                      <div
                        className="edit icon"
                        onClick={() => {
                          openModal();
                          setDebateId(item.id);
                          setAccept(true);
                        }}
                      >
                        <i class="bx bx-check-circle"></i>
                      </div>
                      <div
                        className="delete icon"
                        onClick={() => {
                          openModal();
                          setDebateId(item.id);
                        }}
                      >
                        <i class="bx bx-x-circle"></i>
                      </div>
                    </div>)}
                  </div>
                );
              })}
            </>
          )}

          {visible && (
            <PopUp
              visible={visible}
              type="confirmation"
              closeModal={() => closeModal()}
              action={accept ? acceptDebate : rejectDebate}
              id={debateId}
            />
          )}
          {/* <p className="small-content view">View all (10)</p> */}
        </div>
      </div>
    </div>
    )}
</>
  );
};
