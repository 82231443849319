import React from "react";
import Modal from "react-awesome-modal";
import { ConfirmationPopUp } from "./ConfirmationPopUp";
import { CreateModeratorPopUp } from "./CreateModeratorPopUp";
import { EditContributorPopUp } from "./EditContributorPopUp";
import { RejectPopUp } from "./RejectPopUp";

export const PopUp = (props) => {
  return (
    <div className="popup">
      <Modal
        visible={props.visible}
        width="400"
        className="popup-modal"
        effect="fadeInUp"
        onClickAway={() => props.closeModal()}
      >
        <div className="popup-section ">
          {props.type === "createModerator" ||
          props.type === "editmoderator" ? (
            <CreateModeratorPopUp
              type={props.type}
              updateDOM={props.updateDOM}
              itemObj={props.itemObj}
              closeModal={props.closeModal}
              title={props.title}
            />
          ) : props.type === "confirmation" ? (
            <ConfirmationPopUp
              action={props.action}
              id={props.id}
              closeModal={props.closeModal}
            />
          ) : props.type === "reject" ? (
            <RejectPopUp
              action={props.action}
              id={props.id}
              closeModal={props.closeModal}
            />
          ) : props.type === "editcontributor" ? (
            <EditContributorPopUp
              updateDOM={props.updateDOM}
              itemObj={props.itemObj}
              title={props.title}
              closeModal={props.closeModal}
            />
          ) : (
            ""
          )}
          <a
            href="javascript:void(0);"
            onClick={() => props.closeModal()}
            className="close-icon"
          >
            <i class="bx bx-x"></i>
          </a>
        </div>
      </Modal>
    </div>
  );
};
