import React, { useState, useEffect } from "react";
import { GetPopularDebates } from "../Api/GetApi";
import "./Debates.scss";
import { defaultImage } from "../Utilities/Utils";
import { Loader } from "../Loader/Loader";


export const Debates = () => {
  const [debate, setDebate] = useState([]);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    GetPopularDebates().then((response) => {
      setDebate(response.data);
      setIsLoading(false);

    });
  }, []);

  return (
    <>
    {isloading? <Loader/>: (
    <div className="debates ">
      <div className="section-card">
        <h2 className="title primary-color title-border">Popular Debates</h2>
        <div className="debates-area scroll-bar">
          {debate?.map((item, index) => {
            return (
              <div className="debates-section block-card" key={index}>
                <div className="debates-top-block">
                  <div className="debates-profile">
                    <div className="debates-image">
                      <img
                        src={"uploads/" + item.image}
                        alt="img"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = defaultImage;
                        }}
                      />
                    </div>
                    <div className="profile-info">
                      <h2 className="block-title">{item.name}</h2>
                      <p className="small-content">{item.created_at}</p>
                    </div>
                  </div>
                  <label
                    className="topic-label"
                    style={{
                      color: item.color,
                      backgroundColor: item.color_opacity,
                    }}
                  >
                    {item.category_name}
                  </label>
                </div>
                <h2 className="block-title">{item.title}</h2>
                <div className="btn-block">
                  <button className="transparent-btn">
                    <i className="bx bx-like"></i>
                    For
                    <span>{item?.like_count || 0}</span>
                  </button>
                  <button className="transparent-btn">
                    <i className="bx bx-dislike"></i>
                    Against
                    <span>{item?.dislike_count || 0}</span>
                  </button>
                  <button className="transparent-btn">
                    <i className="bx bx-like"></i>
                    Undecided
                    <span>
                      {" "}
                      <span>{item?.undecided_count || 0}</span>
                    </span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    )}
    </>
  );
};
