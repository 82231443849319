import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { GetCount } from "../Api/GetApi";
import "./PieChart.scss";
export const PieChart = () => {
  const [count, setCount] = useState([]);
  const [acceptedCount, setAcceptedCount] = useState();
  const [rejectedCount, setRejectedCount] = useState();

  useEffect(() => {
    GetCount().then((res) => {
      setCount(res?.data[0]);
      setAcceptedCount(res?.data[0]?.accepted_count);
      setRejectedCount(res?.data[0]?.rejected_count);
    });
  }, []);

  const series = [rejectedCount, acceptedCount];
  const options = {
    labels: ["Rejected", "Accepted"],
    colors: ["#00BF91", "#7B61FF"],

    chart: {
      type: "pie",
      events: {
        animationEnd: function (ctx) {
          ctx.toggleDataPointSelection(1);
        },
      },
    },

    background: {
      borderWidth: 8,
      borderColor: "#fff",
      opacity: 0.9,
    },
    dataLabels:{
      offsetX: -3,
      offsetY: 3,
      dropShadow: {
        enabled: true,
        left: 4,
        top: 2,
        opacity: 0.9
    },
      style: {
        enabled:true,
        colors: ['#fff'],
       
    },
    },
    legend: {
      position: "bottom",
      verticalAlign: "right",

      fontSize: "12px",
      fontFamily: "noto-sans, sans-serif ",
      floating: false,
      markers: {
        shape: "square",
        radius: 5,
      },
    },
  };

  return (
    <div className="pie-chart ">
      <div className="section-card">
        <h2 className="title primary-color title-border"> Reports </h2>
        <div className="piechart-section ">
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            width="100%"
            height="240"
          />
        </div>
      </div>
    </div>
  );
};
