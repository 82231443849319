import { rgbToHex } from "@material-ui/core";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { domain } from "../Api/Url";
import { createFile } from "../Utilities/URLtoFile";

/**
 * @typedef {Object} Category
 * @property {string} category_name
 * @property {string} color
 * @property {{url?:string}} image
 * @property {string} color
 * @property {boolean} has_sub_group
 */

/** @type {React.FC<{
 * categories:Category,
 * subCategories:Array<Category>
 * }>} */
const Helper = ({ categories, subCategories }) => {
  const formik = useFormikContext();

  const initialize = async () => {
    formik.setFieldValue("title", categories?.category_name ?? "");
    let image = [];

    if (categories?.image?.url) {
      image = await createFile(domain + categories?.image?.url);
      if (image) {
        formik.setFieldValue("image", [image]);
      } else {
        formik.setFieldValue("image", []);
      }
    } else {
      formik.setFieldValue("image", []);
    }

    formik.setFieldValue(
      "color",
      (categories.color || "#000000").startsWith("#")
        ? (categories.color || "#000000").slice(0, 7)
        : rgbToHex(categories.color)
    );

    formik.setFieldValue("hasSubcategory", Boolean(categories.has_sub_group));
    let _subCategories = [];
    if (categories.has_sub_group && typeof subCategories === "object") {
      _subCategories = subCategories.map(async (obj) => {
        const image = await createFile(domain + obj.image.url);
        return {
          title: obj.category_name,
          image: [image],
          id: obj.id,
        };
      });
    }
    _subCategories = await Promise.all(_subCategories);
    formik.setFieldValue("subCategories", _subCategories);
  };

  useEffect(() => {
    initialize();
  }, []);

  return null;
};

export default Helper;
