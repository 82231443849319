import React, { useState, useEffect } from "react";
import "./WorldMap.scss";
import { WorldMap } from "react-svg-worldmap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { GetUserCounts } from "../Api/GetApi";

export const WorldMapComponent = () => {
  const [userCount, setUserCount] = useState([]);
  const [totalUser, setTotalUser] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  useEffect(() => {
    GetUserCounts().then((res) => {
      setUserCount(res?.data);
      setTotalUser(res?.data?.total_user[0]);
      setCountryCode(res?.data?.country_code);
    });
  }, []);

  const data = countryCode;

  return (
    <div className="world-map">
      <div className="section-card">
        <h2 className="title primary-color title-border">
          Country wise visited Users
        </h2>
        <div className="row">
          <div className="col-md-8">
            <div className="total-users">
              <p className="content">
                Total visits<span>{totalUser?.count}</span>
              </p>
            </div>
            <WorldMap
              color="#86AF8A"
              value-suffix="people"
              size="xl"
              data={data}
              strokeOpacity=".5"
              backgroundColor="transparent"
            />
          </div>
          <div className="col-md-4">
            <div className="world-map-progressbar">
              {userCount.country_wise_user?.map((item, index) => {
                return (
                  <div className="progressbar-section" key={index}>
                    <p className="content">{item.country}</p>
                    <div className="progressbar-block green-progressbar">
                      <ProgressBar now={item.count} />
                      <p className="progressbar-number content">{item.count}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
