import React, { useState, useEffect } from "react";
import "./CategoryEdit.scss";
import Dropzone from "../Dropzone/Dropzone";

export const CategoriesEdit = () => {
  return (
    <div className="edit-new-category">
      <div className="block-card">
        <h2 className="large-title primary-color">Edit New Category </h2>

        <div className="form-inputs editnew-categories">
          <p className="sub-content">Category</p>
          <div className="category-section">
            <div className="row ">
              <div className="col-md-6">
                <div>
                  <p className="input-content">Category Name</p>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Enter category name"
                  />
                </div>
                <div>
                  <p className="input-content">Select Category Color</p>
                  <input className="input-field" type="text" />
                </div>
                <div className="sub-category-checkbox">
                  <input
                    className="styled-checkbox"
                    id="styled-checkbox-1"
                    type="checkbox"
                    value="value1"
                  />
                  <label className="check-label" htmlFor="styled-checkbox-1">
                    has sub category
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <p className="input-content">Choose Category Image</p>
                <Dropzone />
              </div>
            </div>
          </div>

          <div className="category-section">
            <div className="d-flex">
              <p className="sub-content">Sub Category</p>
              <button className="main-btn">
                <i className="bx bxs-plus-circle"></i>Add new sub-category
              </button>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div>
                  <p className="input-content">Category Name</p>
                  <input
                    type="text"
                    className="input-field"
                    placeholder="Enter sub-category name"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <p className="input-content">Choose Sub-Category Image</p>
                <Dropzone />
              </div>
            </div>
          </div>
        </div>
        <button className="main-btn edit-btn">edit</button>
      </div>
    </div>
  );
};

export default CategoriesEdit;
