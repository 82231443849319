import React, { useState, useEffect, useContext } from "react";
import "./Moderators.scss";
import { GetModeratorList } from "../Api/GetApi";
import { DeleteModeratorApi } from "../Api/DeleteApi";
import { PopUp } from "../PopUp/PopUp";
import { ToasterContext } from "../Context/ToasterContext";
import { defaultImage } from "../Utilities/Utils";

const Moderators = (props) => {
  const [moderator, setModerator] = useState([]);
  const [moderatorId, setModeratorId] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [visible, setVisible] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  useEffect(() => {
    GetModeratorList().then((response) => {
      setModerator(response.data);
    });
  }, [isDeleted]);

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  const deleteModerator = (id) => {
    DeleteModeratorApi(id).then((res) => {
      if (res.data.status === 200) {
        setIsDeleted(true);
        setVisible(false);
        successMessage("Moderator deleted successfully.");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  return (
    <div className="moderators">
      <div className="section-card">
        <h2 className="title primary-color title-border">Moderators</h2>
        {moderator?.slice(0, 3)?.map((item, index) => {
          return (
            <div className="moderators-section" key={index}>
              <div className="moderators-block">
                <div className="moderators-image">
                  <img
                    src={item.image}
                    alt="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                </div>
                <p className="content primary-color">{item.name}</p>
              </div>
              <div className="action-block">
                <a href={`/show_moderator?id=${item.id}`}>
                  <div className="edit icon">
                    <i class="bx bx-show"></i>
                  </div>
                </a>
                <div
                  className="delete icon asdfg"
                  onClick={() => {
                    openModal();
                    setModeratorId(item.id);
                    // deleteModerator(item.id);
                  }}
                >
                  <i class="bx bx-trash"></i>
                </div>
                {visible && (
                  <PopUp
                    visible={visible}
                    type="confirmation"
                    closeModal={() => closeModal()}
                    action={deleteModerator}
                    id={moderatorId}
                  />
                )}
              </div>
            </div>
          );
        })}

        <a href="/moderators">
          <p className="small-content view">View All ({moderator.length})</p>
        </a>
      </div>
    </div>
  );
};

export default Moderators;
