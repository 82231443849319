import React from "react";
import "./ContributorDebators.scss";

const ContributorDebators = (props) => {
  return (
    <div className="contributor-component">
      {props.data.map((item, index) => {
        return (
          <div className="for-content" key={index}>
            <div>
              <p className="sub-text">{item.title}</p>
            </div>

            <div className="d-flex">
              <button
                className={`popular-btn 
                 ${
                   props.index === 0
                     ? "transparent-color"
                     : props.index === 1
                     ? "green-bg"
                     : props.index === 2
                     ? "transparent-color"
                     : ""
                 }`}
              >
                <span>
                  <i class="bx bx-like"></i>
                </span>
                For{" "}
                <span>{item.for_count === null ? "0" : item.for_count}</span>
              </button>

              <button
                className={`popular-btn btn-against 
                 ${
                   props.index === 0
                     ? "transparent-color"
                     : props.index === 1
                     ? "transparent-color"
                     : props.index === 2
                     ? "red-bg"
                     : ""
                 }`}
              >
                <span className="btn-points">
                  <i class="bx bx-dislike"></i>
                </span>
                Against{" "}
                <span className="btn-points">
                  {item.against_count === null ? "0" : item.against_count}
                </span>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ContributorDebators;
