import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

/**@type {React.FC<{files:Array<File>;setFiles:()=>void>} */
const ImageDropzone = React.memo(({ files, setFiles }) => {
  const [_files, _setFiles] = useState([]);

  const callbackWhenFileGetsDropped = useCallback((acceptedFiles) => {
    _setFiles(acceptedFiles);
    setFiles(acceptedFiles);
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    _setFiles(files);
  }, [files]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: callbackWhenFileGetsDropped,
  });

  const filesArray = [..._files].map((file) => (
    <li key={file.path}>
      {file.name} - {file.size} bytes
    </li>
  ));

  return (
    <div className="image-dropzone">
      <section className="">
        <div {...getRootProps({ className: "dropzone dropzone-field" })}>
          <input {...getInputProps()} />
          <p>Browse to choose images</p>
        </div>
        <aside>
          <ul>
            <p>{filesArray}</p>
          </ul>
        </aside>
      </section>
    </div>
  );
});

ImageDropzone.defaultProps = {
  setFiles: () => {},
  files: [],
};

export default ImageDropzone;
