import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./TabComponent.scss";
import PendingDebate from "../DebatesPage/PendingDeabate/PendingDebate";
import { Customtable } from "../CustomTable/CustomTable";
import ContributorDebators from "../ContributorDebtors/ContributorDebtors";
import { Statement } from "../Statement/Statement";

export const TabComponent = (props) => {
  return (
    <div className="tab-component">
      <Tabs>
        <TabList>
          {props.title.map((item, index) => {
            return <Tab key={index}>{item}</Tab>;
          })}
        </TabList>

        {props.type === "debate" ? (
          <>
            {props?.data.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <PendingDebate
                    debateData={item}
                    key={index}
                    debateIndex={index}
                    categoryList={props.categoryList}
                    setUpdateDebate={props.setUpdateDebate}
                    updateDebate={props.updateDebate}
                  />
                </TabPanel>
              );
            })}
          </>
        ) : props.type === "statement" ? (
          <>
            {props?.data.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <Statement
                    statement={item}
                    categoryList={props.categoryList}
                    showCategory="true"
                    action="true"
                    updateStatement={props.updateStatement}
                    setUpdateStatement={props.setUpdateStatement}
                    textLength={1220}
                    statementIndex={index}
                  />
                </TabPanel>
              );
            })}
          </>
        ) : props.type === "contributor" ? (
          <>
            {props.data.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <Customtable
                    data={item}
                    type="contributor"
                    contributorIndex={index}
                    action={props.action}
                    updateDOM={props.updateDOM}
                  />
                </TabPanel>
              );
            })}
          </>
        ) : props.type === "showContributor" ||
          props.type === "showModerator" ? (
          <>
            {props.data.map((item, index) => {
              return (
                <TabPanel key={index}>
                  <ContributorDebators
                    data={item}
                    index={index}
                    type={props.type}
                  />
                </TabPanel>
              );
            })}
          </>
        ) : (
          ""
        )}
      </Tabs>
    </div>
  );
};
