import React, { useEffect, useState } from "react";
import "./AreaChart.scss";
import ReactApexChart from "react-apexcharts";
import { InfoCard } from "../InfoCard/InfoCard";
import { GetAreaChartData } from "../Api/GetApi";

export const AreaChart = (props) => {
  const [weekdays] = useState([]);
  const [seriesData] = useState([]);

  useEffect(() => {
    GetAreaChartData().then((res) => {
      res.data.forEach((day) => {
        weekdays.push(day.visited_day);
        seriesData.push(day.count);
      });
    });
  }, []);

  const series = [
    {
      name: "series2",
      data: seriesData,
    },
  ];
  const options = {
    chart: {
      height: 300,
      type: "area",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      show: false,
    },
    xaxis: {
      type: "category",
      categories: weekdays,
    },
  };

  return (
    <div className="area-chart">
      <div className="row">
        <div className="col-md-8">
          <div className="section-card">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={310}
            />
          </div>
        </div>
        <div className="col-md-4 pl-0">
          <InfoCard />
        </div>
      </div>
    </div>
  );
};
