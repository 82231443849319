import React, { useState, useEffect } from "react";
import TimeAgo from "timeago-react";
import {
  GetAdminNotifications,
  GetModeratorNotifications,
} from "../Api/GetApi";
import "./Notification.scss";
import { defaultImage } from "../Utilities/Utils";

const Notification = (props) => {
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    if (props.user_type === "admin") {
      GetAdminNotifications().then((response) => {
        setNotificationData(response.data.notification);
      });
    } else {
      GetModeratorNotifications().then((response) => {
        setNotificationData(response.data.notification);
      });
    }
  }, []);

  return (
    <div className="notification scroll-bar">
      <div className="title-block">
        <h2 className="title">Notification</h2>
        <a href="/notification-view">
          <p className="view content">See all</p>
        </a>
      </div>
      {notificationData?.slice(0, 10)?.map((item, index) => {
        return (
          <>
            <a href="/notification-view">
              <div className="notification-section" key={index}>
                <div className="notification-image">
                  <img
                    src={"uploads/" + item.user_image}
                    alt="img"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = defaultImage;
                    }}
                  />
                  <div className="verify-icon">
                    <i className="bx bx-check"></i>
                  </div>
                </div>
                <div className="notification-block">
                  <p className="small-content">
                    <span className="bold">{item.description}</span>
                    <p className="small-content time">
                      <TimeAgo datetime={item.created_at} />
                    </p>
                  </p>
                </div>
              </div>
            </a>
          </>
        );
      })}
    </div>
  );
};
export default Notification;
