import React, { useState } from "react";
import { PopUp } from "../PopUp/PopUp";
import { defaultImage } from "../Utilities/Utils";

export const Customtable = (props) => {
  const [visible, setVisible] = useState(false);
  const [itemObj, setItemObj] = useState(0);
  const [id, setId] = useState("");
  const [editPopUp, setEditPopUp] = useState(false);
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [categoryObj, setcategoryObj] = useState(0);

  const openModal = (id) => {
    setEditPopUp(true);
    setVisible(true);
    setItemObj(props.data.find((f) => f.id === id));
    setcategoryObj(props.data.find((f) => f.id === id));
  };
  const closeModal = () => {
    setVisible(false);
    setEditPopUp(false);
    setDeletePopUp(false);
  };

  return (
    <div className="custom-table">
      <table className="table table-borderless">
        <thead>
          <tr>
            <th scope="col" className="content">
              {props.type === "moderator"
                ? "Moderator"
                : props.type === "category"
                ? "Category Name"
                : props.contributorIndex === 0
                ? "Top Debators"
                : "Debators"}
            </th>
            <th scope="col" className="content">
              {props.type === "category" ? "Sub-Category" : "Email"}
            </th>
            <th scope="col" className="content">
              {props.type === "category" ? "Colour" : "Country"}
            </th>
            <th scope="col" className="content">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {props.data?.map((item, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className="custom-table-profile">
                    {props.type === "category" ? (
                      <p className="content">{item.name}</p>
                    ) : (
                      <>
                        <div className="custom-table-image">
                          <img
                            src={item.image}
                            alt="img"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = defaultImage;
                            }}
                          />
                        </div>
                        <p className="content">{item.name}</p>
                      </>
                    )}

                    {props.type === "contributor" ? (
                      <>
                        {props.contributorIndex === 0 && (
                          <div className="top-icon">
                            <img
                              src={require("../../../assets/images/top.png")}
                              alt="icon"
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </td>

                <td>
                  {props.type === "category" ? (
                    <>
                      {item.sub_category_details.sub_category_name === null
                        ? "- -"
                        : (
                            item.sub_category_details.sub_category_name + ","
                          ).replace(/,\s*$/, "")}
                    </>
                  ) : (
                    <>{item.email}</>
                  )}
                </td>
                <td>
                  {props.type === "category" ? (
                    <div className="color-block">
                      <span
                        className="color-code"
                        style={{ background: item.color }}
                      ></span>
                      {item.color}
                    </div>
                  ) : (
                    <>{item.country}</>
                  )}
                </td>
                <td>
                  <div className="action-block">
                    {props.type === "contributor" ? (
                      <a href={`/show_contributor?id=${item.id}`}>
                        <i className="bx bx-show"></i>
                      </a>
                    ) : props.type === "category" ? (
                      ""
                    ) : props.type === "moderator" ? (
                      <a href={`/show_moderator?id=${item.id}`}>
                        {" "}
                        <i className="bx bx-show"></i>{" "}
                      </a>
                    ) : (
                      <i className="bx bx-show"></i>
                    )}

                    {props.type === "category" ? (
                      <a href={`/create_categories?id=${item.id}&mode=edit`}>
                        
                        <i className="bx bx-edit"></i>
                      </a>
                    ) : (
                      ""
                      // <button
                      //   className="plain-btn"
                      //   onClick={() => openModal(item.id)}
                      // >
                      //   <i className="bx bx-edit"></i>
                      // </button>
                    )}

                    <button
                      className="plain-btn"
                      onClick={() => {
                        setDeletePopUp(true);
                        setVisible(true);
                        setId(item.id);
                        // props.action(item.id);
                      }}
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {editPopUp ? (
        <PopUp
          visible={visible}
          type={`edit${props.type}`}
          itemObj={itemObj}
          categoryObj={categoryObj}
          closeModal={() => closeModal()}
          updateDOM={props.updateDOM}
          title={`Edit ${props.type}`}
        />
      ) : (
        ""
      )}

      {deletePopUp ? (
        <PopUp
          visible={visible}
          type="confirmation"
          closeModal={() => closeModal()}
          action={props.action}
          id={id}
        />
      ) : (
        ""
      )}
    </div>
  );
};
