import React from "react";
import "./notFoundPage.scss";

const NotFoundPage = (props) => {
  return (
    <div className="not-found-img text-center">
      <div className="wrapper">
        <img src={require("../../../assets/images/nodata.svg")} alt="image" />
        <p className="img-highlight">No {props.title}</p>
        <p className="img-title">There is no {props.title} at the moment.</p>
      </div>
    </div>
  );
};
export default NotFoundPage;
