import Axios from "axios";

export const DeleteModeratorApi = (id) => {
  return Axios.delete(`backend_api/moderator/delete/${id}`);
};

export const DeleteCategoryApi = (id) => {
  return Axios.delete(`backend_api/category/delete/${id}`);
};

export const DeleteContributorApi = (id) => {
  return Axios.delete(`backend_api/debator/delete/${id}`);
};

export const DeleteQueriesApi = (id) => {
  return Axios.delete(`backend_api/contact_us/delete/${id}`);
};

export const DeleteDebateApi = (id) => {
  return Axios.delete(`backend_api/delete_debate/${id}`);
};

export const DeleteStatementApi = (id) => {
  return Axios.post(`backend_api/delete_comment/${id}`);
};
