import Axios from "axios";

export const postModerator = (data) => {
  return Axios.post("/backend_api/new_user_back", data);
};

export const acceptModerator = (id) => {
  return Axios.post(`/backend_api/become_a_moderator/${id.id}`);
};

export const rejectModerator = (id) => {
  return Axios.post(`/backend_api/moderator/reject/${id.id}`);
};

export const postModeratorUpdate = (data, id) => {
  return Axios.post(`/backend_api/moderator/update/${id}`, data);
};

export const postDebateAccept = (data) => {
  return Axios.post("/backend_api/debate/accept", data);
};

export const postDebateReject = (data) => {
  return Axios.post("backend_api/debate/reject", data);
};

export const postModeratorBlock = (id) => {
  return Axios.post(`backend_api/block_user_front/${id}`);
};

export const acceptDebates = (id) => {
  return Axios.post("backend_api/debate/accept", id);
};

export const rejectDebates = (data) => {
  return Axios.post("backend_api/debate/reject", data);
};

export const postContributorUpdate = (data, id) => {
  return Axios.post(`backend_api/user/edit/${id}`, data);
};

export const postCatergoryCreate = (data) => {
  return Axios.post("backend_api/create/category", data);
};

export const postCatergoryEdit = (data, id) => {
  return Axios.post(`backend_api/edit/category/${id}`, data);
};

export const postStatementAccept = (data) => {
  return Axios.post("/backend_api/comment/accept", data);
};

export const postStatementReject = (data) => {
  return Axios.post("/backend_api/comment/reject", data);
};

export const postCategoryUpdate = (data, id) => {
  return Axios.post(`/backend_api/category/edit/${id}`, data);
};
