import React, { useContext, useEffect, useState } from "react";
import { postDebateAccept, postDebateReject } from "../../Api/PostApi";
import "./PendingDebate.scss";
import parse from "html-react-parser";
import { ToasterContext } from "../../Context/ToasterContext";
import { PopUp } from "../../PopUp/PopUp";
import { DeleteDebateApi } from "../../Api/DeleteApi";
import NotFoundPage from "../../notFoundPage/notFoundPage";
import TimeAgo from "timeago-react";
import { defaultImage } from "../../Utilities/Utils";
import { GetDebates } from "../../Api/GetApi";
import { Loader } from "../../Loader/Loader";

const PendingDebate = (props) => {
  const [debateData, setDebateData] = useState(props.debateData);
  const { successMessage, errorMessage } = useContext(ToasterContext);
  const { debateIndex, categoryList } = props;
  const [showMore, setShowMore] = useState({});
  const [visible, setVisible] = useState(false);
  const [debateId, setDebateId] = useState("");
  const [accept, setAccept] = useState(false);
  const [reject, setReject] = useState(false);
  const [removeDebate, setRemoveDebate] = useState(false);
  const [tabIndex, setTabIndex] = useState(null);
  const [userType, setUserType] = useState("");
  const [userFront, setUserFront] = useState("");
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    GetDebates().then((res) => {
      setUserType(res.data.user_type);
      setUserFront(res.data.user);
      setIsLoading(false);
    });
    console.log("userType", userType);
    console.log("userFront", userFront);
  }, [userType, userFront]);

  console.log(props);

  const filterDebate = (name) => {
    const returnData =
      name === "All"
        ? props.debateData
        : props.debateData.filter((el) => el.category_name === name);
    setDebateData(returnData);
  };

  const acceptDebate = (id) => {
    var postData = {
      debate_id: id,
    };
    postDebateAccept(postData).then((res) => {
      if (res.data.status === 200) {
        props.setUpdateDebate(!props.updateDebate);
        successMessage(res.data.message);
      } else {
        errorMessage("fail");
      }
    });
  };

  const rejectDebate = (id, rejectionReson) => {
    var postData = {
      debate_id: id,
      rejection_reason: rejectionReson,
    };
    postDebateReject(postData).then((res) => {
      if (res.data.status === 200) {
        props.setUpdateDebate(!props.updateDebate);
        successMessage(res.data.message);
      } else {
        errorMessage("fail");
      }
    });
  };

  const toggleText = (id) => {
    setShowMore((prevShowMore) => ({
      ...prevShowMore,
      [id]: !Boolean(prevShowMore[id]),
    }));
  };

  const deleteDebate = (id) => {
    DeleteDebateApi(id).then((res) => {
      if (res.status === 200) {
        props.setUpdateDebate(!props.updateDebate);
        successMessage("Debates deleted successfully !");
      } else {
        errorMessage(res.data.message);
      }
    });
  };

  const openModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <>
      <div className="pending-debate sdfgh">
        <div className="label-btn-block pending-block">
          <button
            className={`label-btn ${
              tabIndex === null ? "active-label-btn" : ""
            }`}
            onClick={() => {
              filterDebate("All");
              setTabIndex(null);
            }}
          >
            ALL
          </button>
          {categoryList?.map((list, index) => {
            return (
              <button
                className={`label-btn ${
                  index === tabIndex ? "active-label-btn" : ""
                }`}
                key={index}
                onClick={() => {
                  filterDebate(list?.category_name);
                  setTabIndex(index);
                }}
              >
                {list?.category_name}
              </button>
            );
          })}
        </div>
        {isloading ? (
          <Loader />
        ) : (
          <>
            <div className="scroll-bar">
              {debateData.length < 1 ? (
                <NotFoundPage
                  title={
                    debateIndex === 0
                      ? "pending debates"
                      : debateIndex === 1
                      ? "approved debates"
                      : "rejected debates"
                  }
                />
              ) : (
                <div className="debate-area scroll-bar">
                  {debateData?.map((item, index) => {
                    return (
                      <div className="card" key={index}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-3">
                              <div className="health-img">
                                <img
                                  src={"uploads/" + item?.image_url}
                                  alt="img"
                                  onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = defaultImage;
                                  }}
                                />
                              </div>
                            </div>
                            <div className="col-md-9">
                              <div className="space-between">
                                <p className="popular-btn">
                                  {item?.category_name}
                                </p>
                                <div className="space-between">
                                  <p className="small-content">
                                    <TimeAgo datetime={item.created_at} />
                                  </p>
                                  {userType === "admin" && (
                                    <div className="drop-down">
                                      <div className="dropdown show">
                                        <a
                                          className="dots"
                                          id="dropdownMenuLink"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"
                                        >
                                          <i className="bx bx-dots-vertical-rounded dots-three"></i>
                                        </a>
                                        <div
                                          className="dropdown-menu action-block"
                                          aria-labelledby="dropdownMenuLink"
                                          aria-haspopup="true"
                                        >
                                          <a className="dropdown-item delete">
                                            <i
                                              className="bx bx-trash"
                                              onClick={() => {
                                                openModal();
                                                setRemoveDebate(true);
                                                setAccept(false);
                                                setReject(false);
                                                setDebateId(item?.id);
                                              }}
                                            ></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-lg-10 col-md-10">
                                  <h5 className="sub-content">{item?.title}</h5>
                                  <p className="description-block">
                                    <span
                                      className={`content description ${
                                        showMore[item.id] ? "show" : "hide"
                                      }`}
                                    >
                                      {parse(`${item?.opinion}`)}
                                    </span>
                                    {item.opinion?.length > 300 && (
                                      <span
                                        className="content read-more"
                                        onClick={() => {
                                          toggleText(item.id);
                                        }}
                                      >
                                        {showMore[item.id]
                                          ? "show less"
                                          : "Read More"}
                                      </span>
                                    )}
                                  </p>
                                </div>
                                <div className="col-md-2">
                                  <div className="btn-block-vertical">
                                    {debateIndex === 0 && (
                                      <>
                                        {item.user_front_id !== userFront ||
                                        userType === "admin" ? (
                                          <>
                                            <button
                                              name="accept"
                                              className="main-btn"
                                              onClick={() => {
                                                openModal();
                                                setDebateId(item?.id);
                                                setReject(false);
                                                setAccept(true);
                                                setRemoveDebate(false);
                                                // acceptDebate(item?.id);
                                              }}
                                            >
                                              Accept
                                            </button>
                                            <button
                                              name="reject"
                                              className="red-btn"
                                              onClick={() => {
                                                openModal();
                                                setDebateId(item?.id);
                                                setAccept(false);
                                                setReject(true);
                                                setRemoveDebate(false);
                                                // rejectDebate(item?.id);
                                              }}
                                            >
                                              Reject
                                            </button>
                                          </>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                    {props?.debateIndex === 1 && (
                                      <button
                                        name="accept"
                                        className="main-btn"
                                      >
                                        Accepted
                                      </button>
                                    )}

                                    {props?.debateIndex === 2 && (
                                      <button name="accept" className="red-btn">
                                        Rejected
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {visible && (
                          <PopUp
                            visible={visible}
                            type={
                              accept
                                ? "confirmation"
                                : reject
                                ? "reject"
                                : removeDebate
                                ? "confirmation"
                                : ""
                            }
                            closeModal={() => closeModal()}
                            action={
                              accept
                                ? acceptDebate
                                : reject
                                ? rejectDebate
                                : removeDebate
                                ? deleteDebate
                                : ""
                            }
                            id={debateId}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default PendingDebate;
