import React, { useState, useEffect, useContext } from "react";
import { Customtable } from "../CustomTable/CustomTable";
import { GetCategories } from "../Api/GetApi";
import { DeleteCategoryApi } from "../Api/DeleteApi";
import { ToasterProvider } from "../Context/ToasterContext";
import { ToasterContext } from "../Context/ToasterContext";

const CategoriesSinglePage = () => {
  const [CategoryData, SetCategoryData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [isDelated, setIsDelated] = useState(false);
  const { successMessage, errorMessage } = useContext(ToasterContext);

  useEffect(() => {
    GetCategories().then((res) => {
      SetCategoryData(res.data);
    });
  }, [isDelated]);

  const closeModal = () => {
    setVisible(false);
  };
  const deleteCategory = (id) => {
    DeleteCategoryApi(id).then((res) => {
      setIsDelated(false);
      if (res.data.status === 200) {
        successMessage("Category successfully deleted !");
        setIsDelated(true);
      } else {
        errorMessage(res.data.message);
      }
    });
  };
  return (
    <div className="categories-single-page">
      <div className="block-card  single-page-height">
        <div className="title-block">
          <h2 className="large-title primary-color">Categories</h2>
          <a href="/create_categories">
            <button className="main-btn">
              <i className="bx bxs-plus-circle"></i>Create new Categories
            </button>
          </a>
        </div>

        <div className="categories-single-table">
          <Customtable
            data={CategoryData}
            action={deleteCategory}
            type="category"
            closeModal={() => closeModal()}
          />
        </div>
      </div>
    </div>
  );
};

export default function CategoryWithContext() {
  return (
    <ToasterProvider>
      <CategoriesSinglePage />
    </ToasterProvider>
  );
}
